import {useState, useEffect} from "react";
import {Collapse, Flex, Text, Transition} from "@mantine/core";
import {RagProcess} from "../types";
import IconChevronDown from "../../../assets/chevron-down.svg?react";
import classes from "./RagProcessRenderer.module.css";

interface Props {
    processes: RagProcess[];
    finished: boolean;
}

const RagProcessRenderer: React.FC<Props> = ({processes, finished}) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [mounted, setMounted] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [seconds, setSeconds] = useState<number>(0);
    const lastProcess = processes[processes.length - 1];

    useEffect(() => {
        if (currentIndex < processes.length - 1) {
            // Timeout to temporary have the time to show all processes
            setTimeout(() => {
                // Unmount current process when new process is received
                setMounted(false);
            }, 3000);
        }
    }, [processes, currentIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!finished) {
                setSeconds((s) => s + 1);
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [finished]);

    if (!lastProcess) {
        return null;
    }

    return (
        <div>
            <Transition
                mounted={mounted || finished}
                transition="fade"
                duration={200}
                onExited={() => {
                    setMounted(true);
                    setCurrentIndex((prev) => prev + 1);
                }}
            >
                {(styles) => (
                    <Flex
                        className={classes.process}
                        align="center"
                        mb="xs"
                        style={styles}
                        onClick={() => setExpanded((p) => !p)}
                    >
                        {finished ? (
                            <Text className={classes.text} size="sm">
                                Thought for {seconds} seconds
                            </Text>
                        ) : (
                            <Text className={classes.shimmer} size="sm">
                                {processes[currentIndex]?.message}
                            </Text>
                        )}
                        {(processes[currentIndex]?.message || finished) && (
                            <IconChevronDown
                                className={classes.chevron}
                                data-expanded={expanded}
                            />
                        )}
                    </Flex>
                )}
            </Transition>
            <Collapse className={classes.processes} in={expanded} mt="xs">
                {processes.map((process, i) => (
                    <Text key={i} size="sm" mb="xs">
                        {process.message}
                    </Text>
                ))}
            </Collapse>
        </div>
    );
};

export default RagProcessRenderer;
