import {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    ComboboxItem,
    Flex,
    OptionsFilter,
    Stack,
    Text,
    TextInput,
    Tooltip,
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {
    Prompt,
    useDeletePromptMutation,
    useGetPromptsQuery,
    useUpdatePromptMutation,
} from "../promptApi";
import PromptEditor from "./editor/PromptEditor";
import PreviewContent from "./PreviewContent";
import Trash from "./svg/trash.svg?react";
import {modals} from "@mantine/modals";
import classes from "./PromptBuilder.module.css";

interface Props {
    prompt: Prompt;
}

const PromptForm = ({prompt}: Props) => {
    const {data} = useGetPromptsQuery();
    const [updatePrompt, {isLoading: uLoading}] = useUpdatePromptMutation();
    const [deletePrompt] = useDeletePromptMutation();
    const [html, setHtml] = useState<string>(prompt.content);

    const form = useForm({
        /* mode: "uncontrolled", */
        initialValues: {
            name: prompt.name,
            tag: prompt.tag,
            content: prompt.content,
        },
        validate: {
            name: (value) => value === "" && "Name is required",
            tag: (value) => value === "" && "Tag is required",
        },
    });

    const tags = Array.from(new Set(data?.map((p) => p.tag) || []));

    useEffect(() => {
        if (prompt) {
            form.setValues({
                name: prompt.name,
                tag: prompt.tag,
                content: prompt.content,
            });
            form.resetDirty();
        }
    }, [prompt]); // eslint-disable-line

    const handleEditorChange = (content: string, htmlContent: string) => {
        form.setFieldValue("content", content);
        setHtml(htmlContent);
    };

    const openDeletePromptModal = () => {
        modals.openConfirmModal({
            title: "Delete Prompt",
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete this prompt?
                </Text>
            ),
            labels: {confirm: "Delete", cancel: "Cancel"},
            confirmProps: {color: "red"},
            onConfirm: () => deletePrompt(prompt.id),
        });
    };

    const handleSubmit = () => {
        updatePrompt({id: prompt.id, ...form.getValues()});
    };

    return (
        <>
            <div className={classes.main}>
                <PromptEditor
                    initialValue={prompt.content}
                    onChange={handleEditorChange}
                />
                <PreviewContent id={prompt.id} content={html} />
            </div>
            <div className={classes.sidebar}>
                <Stack>
                    <TextInput
                        label="Name"
                        placeholder="Enter prompt name"
                        size="sm"
                        {...form.getInputProps("name")}
                    />
                    <Autocomplete
                        value={prompt?.tag}
                        label="Tag"
                        placeholder="Select or enter tag"
                        size="sm"
                        data={tags}
                        filter={optionsFilter}
                        {...form.getInputProps("tag")}
                    />
                    {/* <Select
                            label="Format"
                            size="sm"
                            data={["text", "json"]}
                        /> */}
                    {/*  <Checkbox
                            label="Active"
                            size="sm"
                            onChange={handleActiveToggle}
                        /> */}
                </Stack>
                <Flex justify="space-between" gap="xs" mb="4px">
                    <Tooltip label="Delete prompt" fz="xs">
                        <Button
                            variant="light"
                            color="gray"
                            loading={uLoading}
                            p="8px"
                            size="sm"
                            style={{aspectRatio: 1}}
                            onClick={openDeletePromptModal}
                        >
                            <Trash />
                        </Button>
                    </Tooltip>
                    <Button
                        onClick={handleSubmit}
                        disabled={!form.isDirty()}
                        flex="1 1 auto"
                        size="sm"
                        loading={uLoading}
                    >
                        Save Prompt
                    </Button>
                </Flex>
            </div>
        </>
    );
};

const optionsFilter: OptionsFilter = ({options, search}) => {
    const isOption = (options as ComboboxItem[]).some((option) => {
        return option.value.toLowerCase() === search.toLowerCase();
    });
    const filtered = (options as ComboboxItem[]).filter((option) => {
        return option.label.toLowerCase().includes(search.toLowerCase());
    });
    return isOption ? options : filtered;
};

//Du er en nøyaktig og hjelpsom ekspert på regnskapsføring for små og mellomstore bedrifter. Svar alle spørsmål på norsk. Finn svaret fra siste assistant svar som starter med KHTema. Hvis KHTema inneholder Aktuelle kontoføringer er disse nøyaktige og skal benyttes hvis aktuelt. Hvis du kan konkludere med at KHTema er en lov, så sørg for at loven overstyrer tema, hvis det er uoverenstemmelser.Generer et så nøyaktig og riktig svar som mulig. Prøv å gi korte og konsistente svar. Nevn kilden hvis du kan. Returner svaret i formatert html, slik at det blir lett å lese. Fjern alle instanser av følgende ord i svaret: KHTema, KHReferanse. Hvis du ikke vet svaret, kan du svare 'Jeg vet desverre ikke"

export default PromptForm;
