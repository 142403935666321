import React from "react";
import Markdown, {Components} from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import {CodeBlock} from "./blocks/CodeBlock";
import {ParagraphBlock} from "./blocks/ParagraphBlock";

// Main component that processes and renders markdown
export const GPTStreamParser: React.FC<{text: string}> = ({text}) => {
    // const [debouncedText] = useDebouncedValue(text, 50);
    return (
        <Markdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={
                {
                    code: CodeBlock,
                    p: ParagraphBlock,
                } as Components
            }
        >
            {text}
        </Markdown>
    );
};

// Example markdown content containing references [1], [2], etc.
export const markdownTestContent = `
**Hello, world!**

This is some content with references [1]. 
Here's another reference [2] that should be replaced with a custom component.
And one more reference [3] at the end.

| Syntax      | Description | Test Text     |
| :---        |    :----:   |          ---: |
| Header      | Title       | Here's this   |
| Paragraph   | Text        | And more      |


\`\`\`
{
    "name": "references",
    "children": [
        {
            "id": "1",
            "content": "The first reference",
            "href": "https://example.com"
        },
        {
            "id": "2",
            "content": "this is the second",
            "href": "https://example.org"
        },
        {
            "id": "3",
            "content": "Another ref",
            "href": "https://example.net"
        }
    ]
}
\`\`\`
`;

export const testPrompt = `

`;
