type ReferencesNode = {
    name: "references";
    children: {
        id: string;
        content: string;
        href: string;
    }[];
};

export function isReferenceNode(json: unknown): json is ReferencesNode {
    return (
        typeof json === "object" &&
        json !== null &&
        "name" in json &&
        json.name === "references"
    );
}

export interface TestSuiteEvaluation {
    createdAt: number;
    testsuiteId: string;
    id: string;
    config: {
        providers: string[];
        prompts: Array<{
            raw: string;
            label: string;
        }>;
        tests: Array<{
            description: string;
            vars: Record<string, string>;
            assert: Array<{
                type: string;
                value: string;
            }>;
        }>;
    };
    results: Array<{
        id: string;
        evalId: string;
        promptIdx: number;
        testIdx: number;
        testCase: {
            description: string;
            vars: Record<string, string>;
            assert: Array<{
                type: string;
                value: string;
            }>;
            options: Record<string, unknown>;
            metadata: Record<string, unknown>;
        };
        prompt: {
            raw: string;
            label: string;
        };
        promptId: string;
        error: string;
        score: number;
        success: boolean;
        response: {
            output: string;
            tokenUsage: {
                total: number;
                prompt: number;
                completion: number;
            };
            cached: boolean;
            cost: number;
        };
        gradingResult: {
            pass: boolean;
            score: number;
            reason: string;
            namedScores: Record<string, unknown>;
            tokensUsed: {
                total: number;
                prompt: number;
                completion: number;
                cached: number;
            };
            componentResults: Array<{
                pass: boolean;
                score: number;
                reason: string;
                assertion: {
                    type: string;
                    value: string;
                };
            }>;
            assertion: null | {
                type: string;
                value: string;
            };
        };
        namedScores: Record<string, unknown>;
        provider: {
            id: string;
            label: string;
            config: Record<string, unknown>;
        };
        latencyMs: number;
        cost: number;
        metadata: Record<string, unknown>;
        persisted: boolean;
    }>;
    prompts: Array<{
        raw: string;
        label: string;
        id: string;
        provider: string;
        metrics: {
            score: number;
            testPassCount: number;
            testFailCount: number;
            assertPassCount: number;
            assertFailCount: number;
            totalLatencyMs: number;
            tokenUsage: {
                total: number;
                prompt: number;
                completion: number;
                cached: number;
            };
            namedScores: Record<string, unknown>;
            namedScoresCount: Record<string, unknown>;
            cost: number;
        };
    }>;
    persisted: boolean;
}

export interface EvaluateTestSuite {
    providers: string[]; // Valid provider name (e.g. openai:gpt-4o-mini)
    prompts: string[]; // List of prompts
    tests: string | TestCase[]; // Path to a CSV file, or list of test cases

    defaultTest?: Omit<TestCase, "description">; // Optional: add default vars and assertions on test case
    outputPath?: string | string[]; // Optional: write results to file
}

export interface TestCase {
    // Optional description of what you're testing
    description?: string;

    // Key-value pairs to substitute in the prompt
    vars?: Record<string, string | string[] | object>;

    // Optional list of automatic checks to run on the LLM output
    assert?: Assertion[];

    // Additional configuration settings for the prompt
    // options?: PromptConfig & OutputConfig & GradingConfig;

    // The required score for this test case.  If not provided, the test case is graded pass/fail.
    threshold?: number;

    // Override the provider for this test
    provider?: string;
}

export interface Assertion {
    type: string;
    value?: string;
    threshold?: number; // Required score for pass
    weight?: number; // The weight of this assertion compared to other assertions in the test case. Defaults to 1.
    // provider?: ApiProvider; // For assertions that require an LLM provider
}

export type AssertionType =
    | "answer-relevance"
    | "classifier"
    | "contains-all"
    | "contains-any"
    | "contains-json"
    | "contains-sql"
    | "contains-xml"
    | "contains"
    | "context-faithfulness"
    | "context-recall"
    | "context-relevance"
    | "cost"
    | "equals"
    | "factuality"
    | "human"
    | "icontains-all"
    | "icontains-any"
    | "icontains"
    | "is-json"
    | "is-sql"
    | "is-valid-openai-function-call"
    | "is-valid-openai-tools-call"
    | "is-xml"
    | "javascript"
    | "latency"
    | "levenshtein"
    | "llm-rubric"
    | "model-graded-closedqa"
    | "model-graded-factuality"
    | "moderation"
    | "perplexity-score"
    | "perplexity"
    | "python"
    | "regex"
    | "rouge-l"
    | "rouge-n"
    | "rouge-s"
    | "select-best"
    | "similar"
    | "starts-with"
    | "webhook";

export interface PaginatedResponse<T> {
    items: T[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
    totalPages: number;
}

export interface AITest {
    meta: {
        id: string;
        name: string;
        description: string;
        createdAt: string; // ISO date string
        updatedAt: string; // ISO date string
    };
    vars: TestVariable[];
    asserts: TestAssert[];
}

export interface TestVariable {
    id: string;
    key: string;
    name: string;
    value: string;
}

export interface TestAssert {
    id: string;
    key: string;
    type: string;
    value: string;
    weight: number;
    threshold?: number; // Optional because it can be null
}

export interface TestSuite {
    meta: {
        id?: string;
        name: string;
        description: string;
        createdAt?: string;
        updatedAt?: string;
    };
    providers: string[];
    prompts: string[];
    tests: string[];
}

export type DebugPrompt = {
    id: string;
    name: string;
    description: string;
    value: string;
};

export type DebugProvider = {
    label: string;
    value: string;
};
