import React from "react";
import {Text, ScrollArea, Stack, InputLabel} from "@mantine/core";
import {useGetPromptsQuery} from "../promptApi";
import {resolvePromptContent} from "../utils";

interface Props {
    id: string;
    content: string;
}

const PreviewContent: React.FC<Props> = ({id, content}) => {
    const {data} = useGetPromptsQuery();
    const prompt = data?.find((p) => p.id === id);

    if (!prompt || !data) {
        return <div>Prompt not found</div>;
    }

    const resolvedPrompt = resolvePromptContent(content, data);

    return (
        <Stack pt="xs" h="50%">
            <InputLabel>Resolved Prompt</InputLabel>
            <ScrollArea /* style={{height: "90%"}} */>
                <Text dangerouslySetInnerHTML={{__html: resolvedPrompt}}></Text>
            </ScrollArea>
        </Stack>
    );
};

export default PreviewContent;
