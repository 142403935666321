import {
    ActionIcon,
    Button,
    Center,
    Container,
    Flex,
    Group,
    keys,
    Loader,
    Pagination,
    rem,
    Space,
    Stack,
    Table,
    Text,
    TextInput,
    Title,
    UnstyledButton,
} from "@mantine/core";
import {Scrollbox} from "../shared/Scrollbox";
import IconSelector from "../../../assets/selector.svg?react";
import IconChevronDown from "../../../assets/chevron-down.svg?react";
import IconChevronUp from "../../../assets/chevron-up.svg?react";
import IconSearch from "../../../assets/search.svg?react";
import IconPlay from "../../../assets/player-play.svg?react";
import IconPlayListAdd from "../../../assets/playlist-add.svg?react";
import IconEdit from "../../../assets/edit.svg?react";
import IconReceipt from "../../../assets/receipt.svg?react";
import IconCalendarClock from "../../../assets/calendar-clock.svg?react";
import classes from "./TestRunner.module.css";
import {useLocalStorage} from "@mantine/hooks";
import {useEffect, useState} from "react";

import {NavLink} from "react-router-dom";
import {prompts} from "./data";
import {EvaluateTestSuite, TestSuiteEvaluation} from "../../../types";
import {evaluationApi} from "../evaluationApi";
import {testApi} from "../testApi";

interface RowData {
    id: string;
    name: string;
    description: string;
    testRunId: string;
    formattedTestRunId: string;
    createdAt: string;
    passCount: string;
    testCount: string;
    cost: string;
}

interface ThProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onSort(): void;
}

function Th({children, reversed, sorted, onSort}: ThProps) {
    const Icon = sorted
        ? reversed
            ? IconChevronUp
            : IconChevronDown
        : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{width: rem(16), height: rem(16)}} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data: RowData[], search: string) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data: RowData[],
    payload: {sortBy: keyof RowData | null; reversed: boolean; search: string}
) {
    const {sortBy} = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}

const pageSize = 10;

export function formatTimeAgo(timestamp: number) {
    const date = new Date(timestamp);
    const now = new Date();
    const diffInHours = (now.getTime() - date.getTime()) / (1000 * 60 * 60);
    const rtf = new Intl.RelativeTimeFormat("en", {numeric: "auto"});

    if (diffInHours < 24) {
        return rtf.format(-Math.floor(diffInHours), "hour");
    } else if (diffInHours < 48) {
        return "Yesterday";
    } else if (diffInHours < 168) {
        // 7 days
        return new Intl.DateTimeFormat("en", {weekday: "long"}).format(date);
    } else {
        return new Intl.DateTimeFormat("en", {
            day: "numeric",
            month: "short",
        }).format(date);
    }
}

export function formatTestRunId(
    testRunId: string,
    allTestRuns: TestSuiteEvaluation[]
) {
    // Extract date from format "eval-xxx-2024-11-05T13:29:33"
    const dateMatch = testRunId.match(/\d{4}-\d{2}-\d{2}/);
    if (!dateMatch) return testRunId;

    const date = dateMatch[0].replace(/-/g, "");

    // Count runs for this date
    const runsOnSameDate = allTestRuns
        .filter((run) => run.id.includes(dateMatch[0]))
        .sort((a, b) => b.createdAt - a.createdAt);

    const runNumber =
        runsOnSameDate.length -
        runsOnSameDate.findIndex((run) => run.id === testRunId);
    return `#${date}.${runNumber}`;
}

export const TestRunner = () => {
    const [activePage, setPage] = useState(1);
    const testSuiteQuery = testApi.endpoints.getAllTestSuites.useQuery({
        pageSize,
        page: activePage,
    });
    const testsuites = testSuiteQuery.data?.items ?? [];

    const [testruns, setTestruns] = useLocalStorage<TestSuiteEvaluation[]>({
        key: "testruns",
        defaultValue: [],
    });

    const [runId, setRunId] = useState<string | null>(null);

    const [evaluateTestsuite, evaluateTestsuiteResult] =
        evaluationApi.endpoints.evaluateTestsuite.useMutation();
    const [lazyGetTests] = testApi.endpoints.getTestsByIds.useLazyQuery();

    const data: RowData[] = testsuites.map((ts) => {
        const testsuiteTestruns = testruns.filter(
            (tr) => tr.testsuiteId === ts.meta.id
        );
        const lastTestRun = testsuiteTestruns[testsuiteTestruns.length - 1];

        return {
            id: ts.meta.id ?? "", // not possible to have empty id at this point
            name: ts.meta.name,
            description: ts.meta.description,
            testRunId: lastTestRun?.id ?? "",
            formattedTestRunId: lastTestRun
                ? formatTestRunId(lastTestRun.id, testruns)
                : "",
            createdAt: lastTestRun?.createdAt?.toString() ?? "",
            passCount: lastTestRun?.results
                .filter((r) => r.success)
                .length.toString(),
            testCount: ts.tests.length?.toString(),
            cost: `${lastTestRun?.results.reduce((acc, r) => acc + r.cost, 0)}`,
        };
    });

    // table stuff
    const [search, setSearch] = useState("");
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    useEffect(() => {
        setSortedData(
            sortData(data, {
                sortBy,
                reversed: reverseSortDirection,
                search,
            })
        );
    }, [
        testSuiteQuery.fulfilledTimeStamp,
        evaluateTestsuiteResult.fulfilledTimeStamp,
        runId,
    ]);

    const setSorting = (field: keyof RowData) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, {sortBy: field, reversed, search}));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;
        setSearch(value);
        setSortedData(
            sortData(data, {
                sortBy,
                reversed: reverseSortDirection,
                search: value,
            })
        );
    };

    const handleEvaluateTestsuite = (id: string) => {
        const testsuiteShell = testsuites.find((ts) => ts.meta.id === id);
        if (!testsuiteShell) {
            return;
        }

        setRunId(id);
        lazyGetTests(testsuiteShell.tests).then((tests) => {
            const testsuite: EvaluateTestSuite = {
                providers: testsuiteShell.providers,
                prompts: testsuiteShell.prompts.map(
                    (p) => prompts.find((pr) => pr.id === p)?.value ?? ""
                ),
                tests:
                    tests.data
                        ?.filter((t) =>
                            testsuiteShell.tests.includes(t.meta.id ?? "")
                        )
                        .map((t) => ({
                            description: t.meta.description,
                            vars: t.vars.reduce(
                                (acc, v) => ({
                                    ...acc,
                                    [v.name]: v.value,
                                }),
                                {}
                            ),
                            assert: t.asserts.map((a) => ({
                                type: a.type,
                                value: a.value,
                                threshold: a.threshold,
                                weight: a.weight,
                            })),
                        })) ?? [],
            };

            // const result = evaluate(testsuite.tests, providers, prompts);

            evaluateTestsuite(testsuite)
                .then((result) => {
                    if (!result.error) {
                        setTestruns((prev) => [
                            ...prev,
                            {
                                ...result.data,
                                testsuiteId: testsuiteShell.meta.id,
                            } as TestSuiteEvaluation,
                        ]);
                    }
                })
                .finally(() => {
                    setRunId(null);
                });
        });
    };

    const rows = sortedData.map((row) => (
        <Table.Tr key={row.name}>
            <Table.Td>
                <ActionIcon
                    className={classes.play}
                    color="gray"
                    variant="subtle"
                    radius={"xl"}
                    onClick={() => handleEvaluateTestsuite(row.id)}
                    loading={
                        evaluateTestsuiteResult.isLoading && runId === row.id
                    }
                >
                    <IconPlay style={{height: "1rem", width: "1rem"}} />
                </ActionIcon>
            </Table.Td>
            <Table.Td>
                <NavLink
                    className={classes.navlink}
                    to={`/test-runner/editor/${row.id}`}
                >
                    <Group align="baseline" wrap="nowrap" gap={0}>
                        {row.name}
                        <IconEdit
                            style={{
                                width: rem(16),
                                height: rem(16),
                                marginLeft: rem(8),
                            }}
                        />
                    </Group>
                    <Text c="dimmed" size="xs">
                        {row.description}
                    </Text>
                </NavLink>
            </Table.Td>
            <Table.Td>
                <Button
                    variant="subtle"
                    component={NavLink}
                    className={classes.navlink}
                    to={`/test-runner/result/${row.id}`}
                >
                    <Stack gap={0}>
                        <Text size="md">{row.formattedTestRunId}</Text>
                    </Stack>
                </Button>
            </Table.Td>
            <Table.Td>
                <Stack gap={0}>
                    <Group align="center" gap={"xs"}>
                        <IconCalendarClock
                            style={{width: rem(16), height: rem(16)}}
                        />
                        <Text size="sm">
                            {row.createdAt
                                ? formatTimeAgo(parseInt(row.createdAt))
                                : ""}
                        </Text>
                    </Group>
                    <Group align="center" gap={"xs"}>
                        <IconReceipt
                            style={{
                                width: rem(16),
                                height: rem(16),
                                color: "var(--mantine-color-dimmed)",
                            }}
                        />
                        <Text size="sm" c="dimmed">
                            {row.cost}
                        </Text>
                    </Group>
                </Stack>
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <Scrollbox w="100%" px="xl">
            <Stack mt={"xl"} gap={"xs"}>
                <Group justify="space-between" align="baseline">
                    <Title flex="1" order={1}>
                        Test runner
                    </Title>
                    <Button
                        component={NavLink}
                        to={"/test-runner/editor/new"}
                        variant="light"
                        color="blue"
                        leftSection={
                            <IconPlayListAdd
                                style={{
                                    width: rem(16),
                                    height: rem(16),
                                }}
                            />
                        }
                    >
                        Add new
                    </Button>
                </Group>
                <Text c="dimmed" size="md">
                    Run testsuites to check the quality of your prompts
                </Text>
            </Stack>
            <Space h="xl" />
            <TextInput
                placeholder="Search by any field"
                radius={"xl"}
                mb="md"
                leftSection={
                    <IconSearch style={{width: rem(16), height: rem(16)}} />
                }
                value={search}
                onChange={handleSearchChange}
            />
            <Stack mih={450} justify="space-between">
                <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    miw={300}
                    layout="fixed"
                >
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Th w={"3rem"}></Table.Th>
                            <Th
                                sorted={sortBy === "name"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("name")}
                            >
                                Name
                            </Th>
                            <Th
                                sorted={sortBy === "createdAt"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("createdAt")}
                            >
                                Last run
                            </Th>
                            <Th
                                sorted={sortBy === "createdAt"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("createdAt")}
                            >
                                Time
                            </Th>
                        </Table.Tr>
                    </Table.Tbody>
                    <Table.Tbody>
                        {testSuiteQuery.isLoading ? (
                            <Table.Tr>
                                <Table.Td colSpan={5}>
                                    <Center>
                                        <Loader />
                                    </Center>
                                </Table.Td>
                            </Table.Tr>
                        ) : sortedData.length > 0 ? (
                            rows
                        ) : (
                            <Table.Tr>
                                <Table.Td colSpan={5}>
                                    <Center>
                                        <Text fw={500} ta="center">
                                            Nothing found
                                        </Text>
                                    </Center>
                                </Table.Td>
                            </Table.Tr>
                        )}
                    </Table.Tbody>
                </Table>
                <Flex justify={"center"}>
                    <Pagination
                        total={testSuiteQuery.data?.totalPages ?? 0}
                        value={activePage}
                        onChange={setPage}
                        mt="sm"
                    />
                </Flex>
            </Stack>
        </Scrollbox>
    );
};
