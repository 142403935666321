export interface Message {
    id?: string;
    role: "system" | "user" | "assistant";
    content: string;
}

export interface RagProcess {
    language: string;
    message: string;
    service: string;
    tag: string;
}

export interface RagSource {
    title: string;
    topictype: string;
    topicid: string;
    resourceid: string;
    segmentid?: string;
    bookmark?: string;
    foundin?: string;
    url?: string;
}

export const isRagProcess = (obj: any): obj is RagProcess => {
    const requiredProps = ["language", "message", "service", "tag"];
    return requiredProps.every((prop) => prop in obj);
};

export const isRagSource = (obj: any): obj is RagSource => {
    const requiredProps = ["title", "topictype", "topicid", "resourceid"];
    return requiredProps.every((prop) => prop in obj);
};
