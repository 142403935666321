import {configureStore, ThunkAction, Action} from "@reduxjs/toolkit";
import chatSlice from "../features/chat/chatSlice";
import {chatApi} from "../features/chat/chatApi";
import {evaluationApi} from "../features/promptfoo/evaluationApi";
import {promptApi} from "../features/prompt-builder/promptApi";
import {responseStateMiddleware} from "./responseStateMiddleware";
import {testApi} from "../features/promptfoo/testApi";

const isDevelopment = process.env.NODE_ENV !== "production";

export const store = configureStore({
    reducer: {
        chat: chatSlice,
        [chatApi.reducerPath]: chatApi.reducer,
        [evaluationApi.reducerPath]: evaluationApi.reducer,
        [testApi.reducerPath]: testApi.reducer,
        [promptApi.reducerPath]: promptApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(chatApi.middleware)
            .concat(evaluationApi.middleware)
            .concat(testApi.middleware)
            .concat(promptApi.middleware)
            .concat(responseStateMiddleware),

    devTools: isDevelopment
        ? {
              trace: true,
              traceLimit: 25,
          }
        : false,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
