import {
    ActionIcon,
    Button,
    Container,
    Group,
    Space,
    Stack,
    Textarea,
    TextInput,
    Tooltip,
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useEffect, useState} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {readLocalStorageValue, useLocalStorage} from "@mantine/hooks";
import {v4 as uuid} from "uuid";
import classes from "./TestEditor.module.css";
import {VarsSection} from "./VarsSection";
import {AssertsSection} from "./AssertsSection";
import IconEraser from "../../../assets/eraser.svg?react";
import IconTrash from "../../../assets/trash.svg?react";
import IconArrowLeft from "../../../assets/arrow-left.svg?react";
import {Scrollbox} from "../shared/Scrollbox";
import {AssertionType} from "../../../types";
import {validateItemValue} from "./AssertionItemValue";
import {testApi} from "../testApi";

export type AssertItemType = {
    id?: string;
    key: string;
    type: AssertionType;
    value: string | string[] | number;
    weight: number;
    threshold?: number;
};

export type TestFormValuesType = {
    meta: {
        id?: string;
        name: string;
        description: string;
    };
    vars: {
        id?: string;
        key: string;
        name: string;
        value: string;
    }[];
    asserts: AssertItemType[];
};

const initialValues: TestFormValuesType = {
    meta: {
        name: "",
        description: "",
    },
    vars: [{key: uuid(), name: "", value: ""}],
    asserts: [],
};

export const TestBuilder = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const testId = params.testId || "";
    const isNew = location.pathname.endsWith("/new");
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const testQuery = testApi.endpoints.getTestById.useQuery(testId, {
        skip: !testId || isNew,
    });

    const [createTest, createTestState] =
        testApi.endpoints.createTest.useMutation();
    const [updateTest, updateTestState] =
        testApi.endpoints.updateTest.useMutation();
    const [deleteTest] = testApi.endpoints.deleteTest.useMutation();

    const [, setTestForm] = useLocalStorage<TestFormValuesType>({
        key: "test-form",
        defaultValue: undefined,
    });
    const testForm = readLocalStorageValue<TestFormValuesType>({
        key: "test-form",
    });

    const form = useForm<TestFormValuesType>({
        mode: "uncontrolled",
        initialValues,
        validate: {
            meta: {
                name: (value) =>
                    value.length < 3
                        ? "Name should contain at least 3 characters"
                        : null,
            },
            vars: {
                name: (value) =>
                    value.length < 3
                        ? "Name should contain at least 3 characters"
                        : null,
                value: (value) =>
                    value.length < 3
                        ? "Value should contain at least 3 characters"
                        : null,
            },
            asserts: {
                type: (value) =>
                    !value ? "Assertion type should be selected" : null,
                value: validateItemValue,
                weight: (value) =>
                    value <= 0 ? "Weight should be greater than 0" : null,
            },
        },
        onValuesChange(values) {
            setTestForm(values);
        },
    });

    // load initial values from local storage
    useEffect(() => {
        if (testId && testQuery.data && !isNew) {
            const test = JSON.parse(JSON.stringify(testQuery.data));
            if (form.getValues().meta.id !== testId) {
                if (testQuery.data) {
                    form.setValues(test);
                    setInitialLoadComplete(true);
                }
            }
        } else if (isNew && !initialLoadComplete) {
            if (testForm?.meta?.id) {
                form.setValues(initialValues);
            } else {
                form.setValues(testForm || initialValues);
            }
            setInitialLoadComplete(true);
        }
    }, [testId, testQuery.fulfilledTimeStamp, testForm]);

    const clearForm = () => form.setValues(initialValues);

    const close = () => navigate("/test-builder");

    const handleSave = () => {
        const result = form.validate();
        if (result.hasErrors) {
            return;
        }

        if (testId) {
            if (updateTestState.isLoading) return;
            updateTest({
                id: testId,
                updatedTest: form.getValues(),
            }).then((res) => {
                if (res.error) return;
                clearForm();
                navigate("/test-builder");
            });
        } else {
            if (createTestState.isLoading) return;
            createTest({
                ...form.getValues(),
                meta: {
                    ...form.getValues().meta,
                },
            }).then((res) => {
                if (res.error) return;
                clearForm();
                navigate("/test-builder");
            });
        }
    };

    const handleEraseOrDelete = () => {
        if (testId) {
            deleteTest(testId).then((res) => {
                if (res.error) return;
                clearForm();
                navigate("/test-builder");
            });
        } else {
            clearForm();
        }
    };

    if (!initialLoadComplete) return null;

    return (
        <Container h="100%">
            <Scrollbox>
                <form className={classes.form}>
                    <Stack>
                        <Group justify="space-between" mt={"lg"}>
                            <Group gap={"xs"} flex={1}>
                                <ActionIcon
                                    color="gray"
                                    variant="subtle"
                                    radius={"xl"}
                                    onClick={close}
                                >
                                    <IconArrowLeft
                                        style={{height: "1rem", width: "1rem"}}
                                    />
                                </ActionIcon>
                                <TextInput
                                    variant="unstyled"
                                    size="xl"
                                    autoComplete="off"
                                    type="text"
                                    flex={1}
                                    radius="md"
                                    placeholder="Untitled test"
                                    key={form.key("meta.name")}
                                    {...form.getInputProps("meta.name")}
                                />
                            </Group>
                            <Tooltip
                                withArrow
                                position="top"
                                label={testId ? "Delete test" : "Clear form"}
                            >
                                <ActionIcon
                                    color={testId ? "red" : "gray"}
                                    variant="subtle"
                                    radius={"xl"}
                                    onClick={handleEraseOrDelete}
                                >
                                    {testId ? (
                                        <IconTrash
                                            style={{
                                                height: "1rem",
                                                width: "1rem",
                                            }}
                                        />
                                    ) : (
                                        <IconEraser
                                            style={{
                                                height: "1rem",
                                                width: "1rem",
                                            }}
                                        />
                                    )}
                                </ActionIcon>
                            </Tooltip>
                        </Group>
                        <Textarea
                            placeholder="Test description"
                            variant="filled"
                            radius="md"
                            autosize
                            minRows={3}
                            maxRows={10}
                            key={form.key("meta.description")}
                            {...form.getInputProps("meta.description")}
                        />
                    </Stack>
                    <Space h="xl" />
                    <VarsSection form={form} />
                    <Space h="xl" />
                    <AssertsSection form={form} />
                </form>
                <Space h="xl" />
                <Group>
                    <Button
                        color="green.6"
                        size="md"
                        onClick={handleSave}
                        loading={
                            createTestState.isLoading ||
                            updateTestState.isLoading
                        }
                    >
                        {testId ? "Update" : "Save"}
                    </Button>
                </Group>
                <Space h="xl" />
            </Scrollbox>
        </Container>
    );
};
