import React, {useState} from "react";
import classes from "./AssistantMessage.module.css";
import {Avatar, Button, Flex, Tooltip} from "@mantine/core";
import IconThumbUp from "../../../assets/thumb-up.svg?react";
import IconThumbDown from "../../../assets/thumb-down.svg?react";
import IconCopy from "../../../assets/copy.svg?react";
import IconRefresh from "../../../assets/refresh.svg?react";
import IconCheck from "../../../assets/check.svg?react";
import {GPTStreamParser} from "./stream-parser/GPTStreamParser";
import {RagProcess, RagSource} from "../types";
import RagProcessRenderer from "./RagProcessRenderer";
import RagSourceRenderer from "./RagSourceRenderer";

interface Props {
    content: string;
    isStreaming: boolean;
    processes?: RagProcess[];
    sources?: RagSource[];
    onRegenerate: () => void;
}

const AssistantMessage: React.FC<Props> = ({
    content,
    isStreaming,
    processes,
    sources,
    onRegenerate,
}) => {
    const [ratedGood, setRatedGood] = useState(false);
    const [ratedBad, setRatedBad] = useState(false);
    const [copied, setCopied] = useState(false);

    const handleRateGood = () => {
        setRatedGood((prev) => !prev);
        setRatedBad(false);
    };

    const handleRateBad = () => {
        setRatedBad((prev) => !prev);
        setRatedGood(false);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(content);
        setCopied(true);
    };

    return (
        <div
            className={classes.root}
            onMouseLeave={() => setTimeout(() => setCopied(false), 100)} // Delay because of transition
        >
            <Avatar color="cyan" mr="md">
                AI
            </Avatar>
            <div className={classes.container}>
                <div className={classes.content}>
                    {processes && (
                        <RagProcessRenderer
                            processes={processes}
                            finished={content !== ""}
                        />
                    )}
                    <GPTStreamParser text={content} />
                    {sources && (
                        <RagSourceRenderer
                            sources={sources}
                            mounted={!isStreaming && content !== ""}
                        />
                    )}
                </div>
                {!isStreaming && (
                    <Flex className={classes.hidden} justify="space-between">
                        <Flex gap="2px">
                            <Tooltip label="Rate response as helpful">
                                <Button
                                    variant="message-button"
                                    size="xs"
                                    color="green"
                                    ml="-4px"
                                    style={{
                                        color: ratedGood
                                            ? "var(--mantine-color-green-6)"
                                            : undefined,
                                    }}
                                    onClick={handleRateGood}
                                >
                                    <IconThumbUp height="18px" />
                                </Button>
                            </Tooltip>
                            <Tooltip label="Rate response as not helpful">
                                <Button
                                    variant="message-button"
                                    size="xs"
                                    color="red"
                                    style={{
                                        color: ratedBad
                                            ? "var(--mantine-color-red-6)"
                                            : undefined,
                                    }}
                                    onClick={handleRateBad}
                                >
                                    <IconThumbDown height="18px" />
                                </Button>
                            </Tooltip>
                            <Tooltip label={copied ? "Copied" : "Copy"}>
                                <Button
                                    variant="message-button"
                                    size="xs"
                                    color="cyan"
                                    onClick={handleCopy}
                                >
                                    {copied ? (
                                        <IconCheck height="18px" />
                                    ) : (
                                        <IconCopy height="18px" />
                                    )}
                                </Button>
                            </Tooltip>
                        </Flex>
                        <Tooltip label="Regenerate response">
                            <Button
                                variant="message-button"
                                size="xs"
                                color="gray"
                                onClick={onRegenerate}
                            >
                                <IconRefresh height="18px" />
                            </Button>
                        </Tooltip>
                    </Flex>
                )}
            </div>
        </div>
    );
};

/* const [processesToShow, setProcessesToShow] = useState<RagProcess[]>([]);
const allProcesses: RagProcess[] = [
    {
        message: "Initializing conversation context...",
        language: "en",
        service: "rag",
        tag: "rag",
    },
    {
        message: "Analyzing user query patterns...",
        language: "en",
        service: "rag",
        tag: "rag",
    },
    {
        message: "Processing semantic relationships...",
        language: "en",
        service: "rag",
        tag: "rag",
    },
    {
        message: "Retrieving relevant knowledge base entries...",
        language: "en",
        service: "rag",
        tag: "rag",
    },
    {
        message: "Evaluating contextual relevance...",
        language: "en",
        service: "rag",
        tag: "rag",
    },
    {
        message: "Generating response framework...",
        language: "en",
        service: "rag",
        tag: "rag",
    },
    {
        message: "Synthesizing information...",
        language: "en",
        service: "rag",
        tag: "rag",
    },
    {
        message: "Finalizing response generation...",
        language: "en",
        service: "rag",
        tag: "rag",
    },
];

useEffect(() => {
    const interval = setInterval(() => {
        if (processesToShow.length < allProcesses.length) {
            setProcessesToShow((prev) => [
                ...prev,
                allProcesses[processesToShow.length],
            ]);
        } else {
            clearInterval(interval);
        }
    }, 1000);

    return () => clearInterval(interval);
}, [processesToShow]); */

export default AssistantMessage;
