import {UseFormReturnType} from "@mantine/form";
import {TestFormValuesType} from "./TestEditor";
import {
    ActionIcon,
    Button,
    Group,
    NumberInput,
    Stack,
    Textarea,
} from "@mantine/core";
import IconMinus from "../../../assets/minus.svg?react";
import {useEffect, useState} from "react";

type AssertionItemValueProps = {
    form: UseFormReturnType<TestFormValuesType>;
    index: number;
    item: TestFormValuesType["asserts"][0];
};

const validateString = (value: string) =>
    value.length < 3 ? "Value should contain at least 3 characters" : null;

export const validateItemValue = (
    value: string | string[] | number,
    values: TestFormValuesType,
    path: string
) => {
    let split = path.split(".");
    // @ts-ignore
    let type = values.asserts[split[split.length - 2]].type;
    switch (type) {
        case "contains":
        case "icontains":
        case "equals":
        case "starts-with":
        case "context-recall":
        case "llm-rubric":
        case "regex": {
            return typeof value === "string" && validateString(value);
        }
        case "is-json":
        case "is-sql":
        case "is-xml":
        case "contains-json":
        case "contains-sql":
        case "contains-xml":
        case "answer-relevance":
        case "context-faithfulness":
        case "context-relevance": {
            return null;
        }
        case "cost":
        case "latency":
        case "perplexity-score":
        case "perplexity":
        case "rouge-n": {
            return typeof value === "number" && value < 0
                ? "Value should be greater than 0"
                : null;
        }
        case "icontains-all":
        case "icontains-any":
        case "contains-all":
        case "contains-any": {
            return Array.isArray(value) && value.length > 0
                ? null
                : "Value should be an array";
        }

        default:
            break;
    }
    // TODO: pass type to the validation function
};

const javascriptExample = `
  // Example: check if the output contains a specific substring
  // This will pass if the output contains "hello"
  return output.includes("hello");`;

const pythonExample = `
  # Example: check if the output contains a specific substring
  # This will pass if the output contains "hello"
  return "hello" in output`;

const ValueInput = ({item, index, form}: AssertionItemValueProps) => {
    const [Component, setComponent] = useState<JSX.Element | null>(null);

    useEffect(() => {
        switch (item.type) {
            case "contains":
            case "icontains":
            case "equals":
            case "starts-with":
            case "context-recall":
            case "llm-rubric":
            case "javascript":
            case "python":
            case "regex": {
                const maybeArray: number | string | string[] =
                    form.getValues().asserts[index].value;
                form.setFieldValue(`asserts.${index}.threshold`, undefined);
                if (Array.isArray(maybeArray)) {
                    if (item.type === "javascript") {
                        form.setFieldValue(
                            `asserts.${index}.value`,
                            javascriptExample
                        );
                    } else if (item.type === "python") {
                        form.setFieldValue(
                            `asserts.${index}.value`,
                            pythonExample
                        );
                    } else {
                        form.setFieldValue(
                            `asserts.${index}.value`,
                            maybeArray[0]
                        );
                    }
                }
                setComponent(
                    <Textarea
                        label="Value"
                        variant="filled"
                        resize="vertical"
                        autosize
                        minRows={3}
                        maxRows={10}
                        placeholder="Var value"
                        key={form.key(`asserts.${index}.value`)}
                        {...form.getInputProps(`asserts.${index}.value`)}
                    />
                );
                break;
            }
            case "is-json":
            case "is-sql":
            case "is-xml":
            case "contains-json":
            case "contains-sql":
            case "contains-xml":
            case "answer-relevance":
            case "context-faithfulness":
            case "context-relevance": {
                form.setFieldValue(`asserts.${index}.threshold`, undefined);
                form.setFieldValue(`asserts.${index}.value`, "");
                setComponent(null);
                break;
            }
            case "cost":
            case "latency":
            case "perplexity-score":
            case "perplexity":
            case "rouge-n": {
                const val = form.getValues().asserts[index].threshold;
                if (typeof val !== "number") {
                    form.setFieldValue(`asserts.${index}.threshold`, 0);
                }

                let min = 0;
                let max = 100;
                let step = 1;
                switch (item.type) {
                    case "cost":
                        min = 0;
                        max = 1;
                        step = 0.001;
                        break;
                    case "latency":
                        min = 0;
                        max = 1000;
                        step = 100;
                        break;
                    case "perplexity-score":
                        min = 0;
                        max = 1;
                        step = 0.01;
                        break;
                    case "perplexity":
                        break;
                    case "rouge-n":
                        min = 0;
                        max = 1;
                        step = 0.01;
                        break;
                    default:
                        break;
                }

                setComponent(
                    <NumberInput
                        label="Value"
                        placeholder="Threshold"
                        min={min}
                        max={max}
                        step={step}
                        key={form.key(`asserts.${index}.threshold`)}
                        {...form.getInputProps(`asserts.${index}.threshold`)}
                    />
                );
                break;
            }
            case "icontains-all":
            case "icontains-any":
            case "contains-all":
            case "contains-any": {
                form.setFieldValue(`asserts.${index}.threshold`, 0);
                const arr: number | string | string[] =
                    form.getValues().asserts[index].value;

                if (!Array.isArray(arr)) {
                    form.setFieldValue(`asserts.${index}.value`, [arr]);
                }

                setComponent(
                    <Stack>
                        {(Array.isArray(arr) ? arr : [arr]).map(
                            (value, valueIndex) => (
                                <Group
                                    align="center"
                                    key={form.key(
                                        `asserts.${index}.value.${valueIndex}`
                                    )}
                                >
                                    <Textarea
                                        variant="filled"
                                        resize="vertical"
                                        autosize
                                        flex={1}
                                        minRows={1}
                                        maxRows={10}
                                        placeholder="Var value"
                                        {...form.getInputProps(
                                            `asserts.${index}.value.${valueIndex}`
                                        )}
                                    />
                                    <ActionIcon
                                        color="gray"
                                        variant="subtle"
                                        radius="xl"
                                        onClick={() => {
                                            if (valueIndex > 0) {
                                                form.removeListItem(
                                                    `asserts.${index}.value`,
                                                    valueIndex
                                                );
                                            }
                                        }}
                                    >
                                        <IconMinus
                                            style={{
                                                width: "1rem",
                                                height: "1rem",
                                            }}
                                        />
                                    </ActionIcon>
                                </Group>
                            )
                        )}
                        <Button
                            onClick={() =>
                                form.insertListItem(
                                    `asserts.${index}.value`,
                                    ""
                                )
                            }
                        >
                            Add value
                        </Button>
                    </Stack>
                );
                break;
            }
            default:
                break;
        }
    }, [item.type, form.getValues().asserts[index].value, index]);

    return Component;
};

export const AssertionItemValue = ({
    item,
    index,
    form,
}: AssertionItemValueProps) => {
    return (
        <Stack mt="xs">
            <ValueInput item={item} index={index} form={form} />
        </Stack>
    );
};
