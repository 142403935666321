import {readLocalStorageValue, useLocalStorage} from "@mantine/hooks";
import {useForm} from "@mantine/form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    ActionIcon,
    Box,
    Button,
    Card,
    Code,
    Container,
    Fieldset,
    Group,
    MultiSelect,
    rem,
    Space,
    Spoiler,
    Stack,
    Text,
    Textarea,
    TextInput,
    Title,
    Tooltip,
} from "@mantine/core";
import classes from "./TestRunnerEditor.module.css";
import IconEraser from "../../../assets/eraser.svg?react";
import IconTrash from "../../../assets/trash.svg?react";
import IconArrowLeft from "../../../assets/arrow-left.svg?react";
import {v4 as uuid} from "uuid";
import {useEffect, useState} from "react";
import {VariableDisplay} from "./VariableDisplay";
import {assertionTypeDescriptions} from "../test-builder/data";
import {prompts, providers} from "./data";
import {Scrollbox} from "../shared/Scrollbox";
import {testApi} from "../testApi";

export type TestSuiteValuesType = {
    meta: {
        id?: string;
        name: string;
        description: string;
    };
    providers: string[];
    prompts: string[];
    tests: string[];
};

const initialValues: TestSuiteValuesType = {
    meta: {
        name: "",
        description: "",
    },
    providers: [],
    prompts: [],
    tests: [],
};

export const TestsuiteEditor = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const testsuiteId = params.testsuiteId || "";
    const isNew = location.pathname.endsWith("/new");
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const testSuiteQuery = testApi.endpoints.getTestSuiteById.useQuery(
        testsuiteId,
        {
            skip: !testsuiteId || isNew,
        }
    );
    const testsQuery = testApi.endpoints.getAllTests.useQuery({
        pageSize: 1000,
        page: 1,
    });

    const [createTestSuite, createTestSuiteState] =
        testApi.endpoints.createTestSuite.useMutation();
    const [updateTestSuite, updateTestSuiteState] =
        testApi.endpoints.updateTestSuite.useMutation();
    const [deleteTestSuite] = testApi.endpoints.deleteTestSuite.useMutation();

    const testSuiteForm = readLocalStorageValue<TestSuiteValuesType>({
        key: "testsuite-form",
    });
    const [, setTestsuiteForm] = useLocalStorage<TestSuiteValuesType>({
        key: "testsuite-form",
        defaultValue: undefined,
    });

    const form = useForm<TestSuiteValuesType>({
        mode: "uncontrolled",
        initialValues,
        validate: {
            meta: {
                name: (value) =>
                    value.length < 3
                        ? "Name should contain at least 3 characters"
                        : null,
            },
            providers: (value) =>
                value.length === 0 ? "Select at least one provider" : null,
            prompts: (value) =>
                value.length === 0 ? "Select at least one prompt" : null,
            tests: (value) =>
                value.length === 0 ? "Select at least one test" : null,
        },
        onValuesChange: (values) => {
            setTestsuiteForm(values);
        },
    });

    // load initial values from local storage
    useEffect(() => {
        if (testsuiteId && testSuiteQuery.data && !isNew) {
            if (form.getValues().meta.id !== testsuiteId) {
                const testsuite = JSON.parse(
                    JSON.stringify(testSuiteQuery.data)
                );
                if (testSuiteQuery.data) {
                    form.setValues(testsuite);
                    setInitialLoadComplete(true);
                }
            }
        } else if (isNew && !initialLoadComplete) {
            if (testSuiteForm?.meta?.id) {
                // left over from previous test
                form.setValues(initialValues);
            } else {
                form.setValues(testSuiteForm || initialValues);
            }
            setInitialLoadComplete(true);
        }
    }, [testsuiteId, testSuiteQuery.fulfilledTimeStamp, testSuiteForm]);

    const clearForm = () => form.setValues(initialValues);
    const close = () => navigate("/test-runner");

    const save = (testsuite: TestSuiteValuesType) => {
        if (testsuiteId) {
            updateTestSuite({
                id: testsuiteId,
                updatedTestSuite: testsuite,
            }).then((res) => {
                if (res.error) return;
                clearForm();
                close();
            });
        } else {
            createTestSuite(testsuite).then((res) => {
                if (res.error) return;

                clearForm();
                close();
            });
        }
    };

    const handleSave = () => {
        const result = form.validate();
        if (!result.hasErrors) {
            //todo: notify user about successful save
            const values = form.getValues();
            save({
                ...values,
                meta: {
                    ...values.meta,
                    id: values.meta.id ?? uuid(),
                },
            });
        }
    };

    const handleEraseOrDelete = () => {
        if (testsuiteId) {
            deleteTestSuite(testsuiteId).then((res) => {
                if (res.error) return;
                clearForm();
                close();
            });
        } else {
            clearForm();
        }
    };

    if (!initialLoadComplete) return null;

    const promptsInUse = form
        .getValues()
        .prompts.map((prompt) => prompts.find((p) => p.id === prompt));
    const variableRegex = /{{(.*?)}}/g;
    const variablesInPrompts = new Set<string>();
    promptsInUse.forEach((prompt) => {
        let match;
        while ((match = variableRegex.exec(prompt?.value ?? ""))) {
            variablesInPrompts.add(match[1]);
        }
    });
    /**
     * TODO: All test variables need to exist in ALL prompts
     */
    const variablesInTests = new Set<string>();
    form.getValues().tests.forEach((test) => {
        const selectedTest = testsQuery.data?.items.find(
            (t) => t.meta.id === test
        );
        selectedTest?.vars.forEach((v) => variablesInTests.add(v.name));
    });
    const unusedVariables = new Set<string>();
    variablesInTests.forEach((variable) => {
        if (!variablesInPrompts.has(variable)) unusedVariables.add(variable);
    });
    const variablesEmpty =
        variablesInPrompts.size === 0 && variablesInTests.size === 0;

    const tooltipLabelPromptNotTest =
        "This variable exist in the prompt but not in the tests";
    const tooltipLabelTestNotPrompt =
        "This variable exist in the tests but not in the prompt";
    const tooltipLabelBoth =
        "This variable exist in both the prompt and the tests";

    return (
        <Container h="100%">
            <Scrollbox>
                <form className={classes.form}>
                    <Stack>
                        <Group justify="space-between" mt={"lg"}>
                            <Group gap={"xs"} flex={1}>
                                <ActionIcon
                                    color="gray"
                                    variant="subtle"
                                    radius={"xl"}
                                    onClick={close}
                                >
                                    <IconArrowLeft
                                        style={{height: "1rem", width: "1rem"}}
                                    />
                                </ActionIcon>
                                <TextInput
                                    variant="unstyled"
                                    size="xl"
                                    autoComplete="off"
                                    type="text"
                                    flex={1}
                                    radius="md"
                                    placeholder="Untitled testsuite"
                                    key={form.key("meta.name")}
                                    {...form.getInputProps("meta.name")}
                                />
                            </Group>
                            <Tooltip
                                withArrow
                                position="top"
                                label={
                                    testsuiteId
                                        ? "Delete testsuite"
                                        : "Clear form"
                                }
                            >
                                <ActionIcon
                                    color={testsuiteId ? "red" : "gray"}
                                    variant="subtle"
                                    aria-label="Clear form"
                                    title="Clear form"
                                    radius={"xl"}
                                    onClick={handleEraseOrDelete}
                                >
                                    {testsuiteId ? (
                                        <IconTrash
                                            style={{
                                                height: "1rem",
                                                width: "1rem",
                                            }}
                                        />
                                    ) : (
                                        <IconEraser
                                            style={{
                                                height: "1rem",
                                                width: "1rem",
                                            }}
                                        />
                                    )}
                                </ActionIcon>
                            </Tooltip>
                        </Group>
                        <Textarea
                            placeholder="Testsuite description"
                            variant="filled"
                            radius="md"
                            autosize
                            minRows={3}
                            maxRows={10}
                            key={form.key("meta.description")}
                            {...form.getInputProps("meta.description")}
                        />
                    </Stack>
                    <Space h="xl" />
                    <Fieldset legend="Providers and prompts" variant="unstyled">
                        <Text c="dimmed" mb="md">
                            Select the providers and prompts to run against. The
                            total number of tests to be run is the product of
                            the number of providers, prompts, and tests.
                        </Text>
                        <Space h="xs" />
                        <MultiSelect
                            label="Providers to run against"
                            placeholder="Select providers"
                            data={providers}
                            key={form.key("providers")}
                            {...form.getInputProps("providers")}
                        />
                        <Space h="lg" />
                        <Stack>
                            <MultiSelect
                                label="Prompts to run against"
                                placeholder="Select prompts"
                                data={prompts.map((prompt) => ({
                                    label: prompt.name,
                                    value: prompt.id,
                                }))}
                                key={form.key("prompts")}
                                {...form.getInputProps("prompts")}
                            />
                            <Space h="sm" />
                            <Fieldset
                                legend="Variable overview"
                                variant="unstyled"
                            >
                                {variablesEmpty ? (
                                    <Text c="dimmed">
                                        <em>No variables to display</em>
                                    </Text>
                                ) : (
                                    <Text c="dimmed">
                                        Variables used in the prompts and tests
                                    </Text>
                                )}
                                <Text mt="xs">
                                    {Array.from(variablesInPrompts).map(
                                        (variable) => (
                                            <VariableDisplay
                                                key={variable}
                                                variable={variable}
                                                variablesToTestAgainst={
                                                    variablesInTests
                                                }
                                                affirmativeTooltip={
                                                    tooltipLabelBoth
                                                }
                                                negativeTooltip={
                                                    tooltipLabelPromptNotTest
                                                }
                                            />
                                        )
                                    )}
                                    {Array.from(unusedVariables).map(
                                        (variable) => (
                                            <VariableDisplay
                                                key={variable}
                                                variable={variable}
                                                negativeTooltip={
                                                    tooltipLabelTestNotPrompt
                                                }
                                            />
                                        )
                                    )}
                                </Text>
                            </Fieldset>
                            {form.getValues().prompts.length > 0 && (
                                <Box>
                                    <Text size="lg" mt="lg" mb={0}>
                                        Prompts
                                    </Text>
                                    <Text c="dimmed">The selected prompts</Text>
                                    <Space h="sm" />
                                    <Group wrap="wrap" align="flex-start">
                                        {form
                                            .getValues()
                                            .prompts.map((prompt) => {
                                                const selectedPrompt =
                                                    prompts.find(
                                                        (p) => p.id === prompt
                                                    );
                                                return (
                                                    <Card
                                                        key={selectedPrompt?.id}
                                                        withBorder
                                                        radius="md"
                                                        p="xl"
                                                        className={classes.card}
                                                    >
                                                        <Stack>
                                                            <Title
                                                                order={3}
                                                                size="lg"
                                                            >
                                                                {
                                                                    selectedPrompt?.name
                                                                }
                                                            </Title>
                                                            <Text
                                                                size="sm"
                                                                c="dimmed"
                                                            >
                                                                {
                                                                    selectedPrompt?.description
                                                                }
                                                            </Text>
                                                        </Stack>
                                                        <Spoiler
                                                            maxHeight={60}
                                                            showLabel="Show more"
                                                            hideLabel="Hide"
                                                        >
                                                            <Code>
                                                                {
                                                                    selectedPrompt?.value
                                                                }
                                                            </Code>
                                                        </Spoiler>
                                                    </Card>
                                                );
                                            })}
                                    </Group>
                                </Box>
                            )}
                            <Space h="sm" />
                            <Fieldset legend="Tests" variant="unstyled">
                                <Text c="dimmed" mb="md">
                                    Select the tests to run against the prompts.
                                </Text>

                                <MultiSelect
                                    placeholder="Select tests"
                                    data={testsQuery.data?.items.map(
                                        (test) => ({
                                            label: test.meta.name,
                                            value: test.meta.id,
                                        })
                                    )}
                                    key={form.key("tests")}
                                    {...form.getInputProps("tests")}
                                />
                                <Group wrap="wrap" mt="lg" align="flex-start">
                                    {form.getValues().tests.map((test) => {
                                        const selectedTest =
                                            testsQuery.data?.items.find(
                                                (t) => t.meta.id === test
                                            );
                                        if (!selectedTest) return null;
                                        return (
                                            <Card
                                                key={selectedTest?.meta.id}
                                                withBorder
                                                radius="md"
                                                miw={300}
                                                maw={400}
                                                className={classes.card}
                                            >
                                                <Card.Section>
                                                    <Stack>
                                                        <Title
                                                            order={3}
                                                            size="lg"
                                                        >
                                                            {
                                                                selectedTest
                                                                    ?.meta.name
                                                            }
                                                        </Title>
                                                        <Text
                                                            size="sm"
                                                            c="dimmed"
                                                        >
                                                            {
                                                                selectedTest
                                                                    ?.meta
                                                                    .description
                                                            }
                                                        </Text>
                                                    </Stack>
                                                </Card.Section>
                                                <Space h="lg" />
                                                {(selectedTest?.vars || [])
                                                    .length > 0 && (
                                                    <Card.Section
                                                        className={
                                                            classes.cardSection
                                                        }
                                                    >
                                                        <Group
                                                            wrap="wrap"
                                                            gap={rem(8)}
                                                            pt={rem(16)}
                                                        >
                                                            {selectedTest?.vars.map(
                                                                (v) => (
                                                                    <VariableDisplay
                                                                        key={
                                                                            v.key
                                                                        }
                                                                        variant="padded"
                                                                        variable={
                                                                            v.name
                                                                        }
                                                                        variablesToTestAgainst={
                                                                            variablesInPrompts
                                                                        }
                                                                        affirmativeTooltip={
                                                                            tooltipLabelBoth
                                                                        }
                                                                        negativeTooltip={
                                                                            tooltipLabelTestNotPrompt
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </Group>
                                                    </Card.Section>
                                                )}
                                                <Space h="lg" />
                                                <Card.Section
                                                    className={
                                                        classes.cardSection
                                                    }
                                                >
                                                    <Group
                                                        px={rem(16)}
                                                        mt={rem(16)}
                                                        gap={rem(8)}
                                                        wrap="wrap"
                                                    >
                                                        {selectedTest?.asserts.map(
                                                            (assert) => (
                                                                <Tooltip
                                                                    key={
                                                                        assert.id
                                                                    }
                                                                    label={
                                                                        assertionTypeDescriptions[
                                                                            assert
                                                                                .type
                                                                        ] ??
                                                                        "No description"
                                                                    }
                                                                >
                                                                    <Text
                                                                        key={
                                                                            assert.key
                                                                        }
                                                                    >
                                                                        <Code>
                                                                            {
                                                                                assert.type
                                                                            }
                                                                        </Code>
                                                                        <Code>
                                                                            =
                                                                        </Code>
                                                                        <Code>
                                                                            {
                                                                                assert.value
                                                                            }
                                                                        </Code>
                                                                    </Text>
                                                                </Tooltip>
                                                            )
                                                        )}
                                                    </Group>
                                                </Card.Section>
                                            </Card>
                                        );
                                    })}
                                </Group>
                            </Fieldset>
                        </Stack>
                    </Fieldset>
                </form>
                <Space h="xl" />
                <Group>
                    <Button
                        color="green.6"
                        size="md"
                        onClick={handleSave}
                        loading={
                            createTestSuiteState.isLoading ||
                            updateTestSuiteState.isLoading
                        }
                    >
                        {testsuiteId ? "Update" : "Save"}
                    </Button>
                </Group>
                <Space h="xl" />
            </Scrollbox>
        </Container>
    );
};
