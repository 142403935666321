import {Flex, Select} from "@mantine/core";
import {useGetPromptsQuery} from "../../prompt-builder/promptApi";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setSystemPromptId} from "../../chat/chatSlice";

const ChatSettings = () => {
    const promptId = useAppSelector((state) => state.chat.promptId);
    const {data} = useGetPromptsQuery();
    const dispatch = useAppDispatch();

    const systemPrompts = data
        ?.filter((p) => p.tag === "system")
        .map((p) => ({value: p.id, label: p.name}));

    return (
        <Flex
            justify="end"
            p="xs"
            style={{
                borderBottom: "1px solid var(--app-shell-border-color)",
            }}
        >
            <Select
                value={promptId}
                data={systemPrompts}
                allowDeselect={false}
                size="sm"
                onChange={(id) => dispatch(setSystemPromptId(id as string))}
            />
        </Flex>
    );
};
export default ChatSettings;
