import {useMemo} from "react";
import {ReferencesBlock} from "./ReferencesBlock";
import {isReferenceNode} from "../../../../../types";
import {Loader} from "@mantine/core";
import classes from "./CodeBlock.module.css";

function renderNode(json: any) {
    if (isReferenceNode(json)) {
        return <ReferencesBlock items={json.children} />;
    }

    return null;
}

type CodeBlockProps = {
    children: string;
    node: {
        type: "element";
        tagName: "code";
        properties: unknown;
        position: {
            start: {line: number; column: number; offset: number};
            end: {line: number; column: number; offset: number};
        };
        children: {type: "text"; value: string}[];
    };
};

export const CodeBlock = ({children}: CodeBlockProps) => {
    const rendered = useMemo(() => {
        try {
            const json = JSON.parse(children);

            return renderNode(json);
        } catch (e) {
            // console.error("Failed to parse JSON", children, e);
            // Perhaps incomplete JSON, so just render loading

            return (
                <Loader className={classes.loader} type="dots" color="black" />
            );
        }
    }, [children]);

    return rendered;
};
