import {useLocalStorage} from "@mantine/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {TestSuiteEvaluation} from "../../../types";
import {testApi} from "../testApi";
import {ActionIcon, Grid, Group, Text, Title} from "@mantine/core";
import {Scrollbox} from "../shared/Scrollbox";
import IconArrowLeft from "../../../assets/arrow-left.svg?react";
import {formatTestRunId} from "../test-runner/TestRunner";
import {prompts} from "../test-runner/data";
import classes from "./TestRunViewer.module.css";

const TestRunViewer = () => {
    const navigate = useNavigate();
    const {testsuiteId, testRunId} = useParams();
    const [testruns] = useLocalStorage<TestSuiteEvaluation[]>({
        key: "testruns",
        defaultValue: [],
    });
    const testsuiteQuery = testApi.endpoints.getTestSuiteById.useQuery(
        testsuiteId ?? "",
        {
            skip: !testsuiteId,
        }
    );
    const testsQuery = testApi.endpoints.getTestsByIds.useQuery(
        testsuiteQuery.data?.tests ?? [],
        {
            skip: !testsuiteId,
        }
    );
    const varNamesInTests = testsQuery.data
        ?.map((test) => test.vars.map((v) => v.name))
        .flat();

    const testsuiteRuns = testruns
        .filter((run) => run.testsuiteId === testsuiteId)
        .sort((a, b) => b.createdAt - a.createdAt);

    const testSuiteRun = testsuiteRuns.find((run) => run.id === testRunId);

    const testRunFormattedId = testRunId
        ? formatTestRunId(testRunId, testsuiteRuns)
        : "";

    const promptList = testsuiteQuery.data?.prompts.map((prompt) =>
        prompts.find((p) => p.id === prompt)
    );

    if (!testSuiteRun) {
        return <div>Test run not found</div>;
    }

    const headerRows = (
        <>
            <Grid.Col className={classes.gridCol} span={4}>
                {varNamesInTests && (
                    <Grid>
                        {varNamesInTests.map((key) => (
                            <Grid.Col
                                className={classes.gridCol}
                                span="auto"
                                style={{whiteSpace: "nowrap"}}
                            >
                                {key}
                            </Grid.Col>
                        ))}
                    </Grid>
                )}
            </Grid.Col>
            <Grid.Col className={classes.gridCol} span={8}>
                <Grid>
                    {promptList?.map((prompt) => (
                        <Grid.Col className={classes.gridCol} span="auto">
                            <Text>{prompt?.value.substring(0, 255)}...</Text>
                        </Grid.Col>
                    ))}
                </Grid>
            </Grid.Col>
        </>
    );

    const rows = testSuiteRun.results.map((result) => (
        <>
            <Grid.Col className={classes.gridCol} span={4}>
                <Grid>
                    {varNamesInTests?.map((key) => (
                        <Grid.Col className={classes.gridCol} span="auto">
                            {result.testCase.vars[key] && (
                                <Text>{result.testCase.vars[key]}</Text>
                            )}
                        </Grid.Col>
                    ))}
                </Grid>
            </Grid.Col>
            <Grid.Col className={classes.gridCol} span={8}>
                <Grid>
                    {promptList?.map(() => (
                        <Grid.Col className={classes.gridCol} span="auto">
                            <Text>{result.response.output}</Text>
                        </Grid.Col>
                    ))}
                </Grid>
            </Grid.Col>
        </>
    ));

    return (
        <Scrollbox w="100%" p="xl">
            <Group gap="xs" flex={1} mb="xl">
                <ActionIcon
                    color="gray"
                    variant="subtle"
                    radius="xl"
                    onClick={() => navigate(-1)}
                >
                    <IconArrowLeft />
                </ActionIcon>
                <Title order={1} size="xl">
                    <Group gap="xs">
                        <Text>{testsuiteQuery.data?.meta.name}</Text>

                        <Text>{testRunFormattedId}</Text>
                    </Group>
                </Title>
            </Group>
            <Grid>
                <Grid.Col span={4}>Variables</Grid.Col>
                <Grid.Col span={8}>Outputs</Grid.Col>
                {headerRows}
                {rows}
            </Grid>
        </Scrollbox>
    );
};

export default TestRunViewer;
