import {Button, Flex, Stack, Text, Transition} from "@mantine/core";
import {RagSource} from "../types";
/* import {ReferencesBlock} from "./stream-parser/blocks/ReferencesBlock"; */
import ExternalLink from "../../../assets/external-link.svg?react";

interface Props {
    sources: RagSource[];
    mounted: boolean;
}

const RagSourceRenderer: React.FC<Props> = ({sources, mounted}) => {
    return (
        <Transition
            mounted={mounted}
            transition="fade"
            duration={500}
            exitDuration={0}
        >
            {(styles) => (
                <Stack style={styles} gap="xs" mt="md">
                    <Text size="sm" fw={600} c="gray.8">
                        Kilder:
                    </Text>
                    <Flex gap="xs">
                        {sources.map((source, i) => (
                            <a
                                href={source.url}
                                key={i}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button
                                    key={i}
                                    rightSection={<ExternalLink height={16} />}
                                    variant="outline"
                                    size="xs"
                                >
                                    {source.title}
                                </Button>
                            </a>
                        ))}
                        {/* <ReferencesBlock
                            items={sources.map((source) => ({
                                id: source.url,
                                content: source.title,
                                href: source.url,
                            }))}
                        /> */}
                    </Flex>
                </Stack>
            )}
        </Transition>
    );
};

export default RagSourceRenderer;
