import {useEffect, useState} from "react";
import {
    Table,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Container,
    Stack,
    Title,
    Button,
    Flex,
    Pagination,
    Space,
    Loader,
} from "@mantine/core";

import IconSelector from "../../../assets/selector.svg?react";
import IconChevronDown from "../../../assets/chevron-down.svg?react";
import IconChevronUp from "../../../assets/chevron-up.svg?react";
import IconSearch from "../../../assets/search.svg?react";
import IconPlayListAdd from "../../../assets/playlist-add.svg?react";
import IconEdit from "../../../assets/edit.svg?react";
import classes from "./TestLibrary.module.css";
import {NavLink} from "react-router-dom";
import {Scrollbox} from "../shared/Scrollbox";
import {chunk} from "../test-runner/utils";
import {testApi} from "../testApi";

interface RowData {
    id: string;
    name: string;
    description: string;
    varCount: string;
    assertCount: string;
}

interface ThProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onSort(): void;
}

function Th({children, reversed, sorted, onSort}: ThProps) {
    const Icon = sorted
        ? reversed
            ? IconChevronUp
            : IconChevronDown
        : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{width: rem(16), height: rem(16)}} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data: RowData[], search: string) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data: RowData[],
    payload: {sortBy: keyof RowData | null; reversed: boolean; search: string}
) {
    const {sortBy} = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}

const pageSize = 10;

export function TestLibrary() {
    // const [tests] = useLocalStorage<TestFormValuesType[]>({
    //     key: "tests",
    //     defaultValue: [],
    // });
    const [activePage, setPage] = useState(1);
    const testsQuery = testApi.endpoints.getAllTests.useQuery({
        pageSize,
        page: activePage,
    });
    const tests = testsQuery.data?.items ?? [];
    const data = tests.map((test) => ({
        id: test.meta.id ?? "", // not possible to have empty id at this point
        name: test.meta.name,
        description: test.meta.description,
        varCount: test.vars.length?.toString(),
        assertCount: test.asserts.length?.toString(),
    }));

    // table stuff
    const [search, setSearch] = useState("");
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    useEffect(() => {
        setSortedData(
            sortData(data, {
                sortBy,
                reversed: reverseSortDirection,
                search,
            })
        );
    }, [testsQuery.fulfilledTimeStamp]);

    const setSorting = (field: keyof RowData) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, {sortBy: field, reversed, search}));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;
        setSearch(value);
        setSortedData(
            sortData(data, {
                sortBy,
                reversed: reverseSortDirection,
                search: value,
            })
        );
    };
    const chunkedData = chunk(sortedData, pageSize);
    const displayData = chunkedData[activePage - 1] ?? [];

    const rows = displayData.map((row) => (
        <Table.Tr key={row.id}>
            <Table.Td>
                <NavLink
                    className={classes.navlink}
                    to={`/test-builder/editor/${row.id}`}
                >
                    <Group align="baseline" wrap="nowrap" gap={0}>
                        {row.name}
                        <IconEdit
                            style={{
                                width: rem(16),
                                height: rem(16),
                                marginLeft: rem(8),
                            }}
                        />
                    </Group>
                </NavLink>
            </Table.Td>
            <Table.Td>{row.description}</Table.Td>
            <Table.Td>{row.varCount}</Table.Td>
            <Table.Td>{row.assertCount}</Table.Td>
        </Table.Tr>
    ));

    return (
        <Container h="100%">
            <Scrollbox>
                <Stack mt={"xl"} gap={"xs"}>
                    <Group justify="space-between" align="baseline">
                        <Title flex="1" order={1}>
                            Test library
                        </Title>
                        <Button
                            component={NavLink}
                            to={"/test-builder/editor/new"}
                            variant="light"
                            color="blue"
                            leftSection={
                                <IconPlayListAdd
                                    style={{
                                        width: rem(16),
                                        height: rem(16),
                                    }}
                                />
                            }
                        >
                            Add new
                        </Button>
                    </Group>
                    <Text c="dimmed" size="md">
                        Create tests and manage them. Include them in testsuites
                        to run them in bulk.
                    </Text>
                </Stack>
                <Space h="xl" />

                <TextInput
                    placeholder="Search by any field"
                    mb="md"
                    radius={"xl"}
                    leftSection={
                        <IconSearch style={{width: rem(16), height: rem(16)}} />
                    }
                    value={search}
                    onChange={handleSearchChange}
                />
                <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    miw={300}
                    layout="fixed"
                    mb={rem(80)}
                >
                    <Table.Tbody>
                        <Table.Tr>
                            <Th
                                sorted={sortBy === "name"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("name")}
                            >
                                Name
                            </Th>
                            <Th
                                sorted={sortBy === "description"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("description")}
                            >
                                Description
                            </Th>
                            <Th
                                sorted={sortBy === "varCount"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("varCount")}
                            >
                                Vars
                            </Th>
                            <Th
                                sorted={sortBy === "assertCount"}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting("assertCount")}
                            >
                                Asserts
                            </Th>
                        </Table.Tr>
                    </Table.Tbody>
                    <Table.Tbody>
                        {testsQuery.isLoading ? (
                            <Table.Tr>
                                <Table.Td colSpan={4}>
                                    <Center>
                                        <Loader />
                                    </Center>
                                </Table.Td>
                            </Table.Tr>
                        ) : rows.length > 0 ? (
                            rows
                        ) : (
                            <Table.Tr>
                                <Table.Td
                                    colSpan={
                                        Object.keys(data?.[0] ?? {}).length
                                    }
                                >
                                    <Text fw={500} ta="center">
                                        Nothing found
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        )}
                    </Table.Tbody>
                </Table>
                <Flex justify={"center"}>
                    <Pagination
                        total={testsQuery.data?.totalPages ?? 0}
                        value={activePage}
                        onChange={setPage}
                        mt="sm"
                    />
                </Flex>
            </Scrollbox>
        </Container>
    );
}
