import {
    Autocomplete,
    Button,
    ComboboxItem,
    Flex,
    Modal,
    OptionsFilter,
    Stack,
    TextInput,
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useCreatePromptMutation, useGetPromptsQuery} from "../promptApi";
import {useNavigate} from "react-router-dom";

interface Props {
    opened: boolean;
    onClose: () => void;
}

const NewPromptModal: React.FC<Props> = ({opened, onClose}) => {
    const [createPrompt] = useCreatePromptMutation();
    const navigate = useNavigate();
    const {data} = useGetPromptsQuery();
    const tags = Array.from(new Set(data?.map((p) => p.tag) || []));

    const form = useForm({
        initialValues: {
            name: "",
            tag: "",
        },
        validate: {
            name: (value) => value === "" && "Name is required",
            tag: (value) => value === "" && "Tag is required",
        },
    });

    const handleClose = () => {
        form.reset();
        onClose();
    };

    const handleCreate = () => {
        form.validate();

        if (form.isValid()) {
            createPrompt({
                name: form.values.name,
                tag: form.values.tag,
                content: "",
                onSuccess: (data) => {
                    navigate(`/prompt-builder/${data.id}`);
                },
            });
            form.reset();
            onClose();
        }
    };

    return (
        <Modal
            title="Create Prompt"
            opened={opened}
            centered
            onClose={handleClose}
        >
            <Stack gap="xs">
                <TextInput
                    label="Name"
                    data-autofocus
                    {...form.getInputProps("name")}
                />
                <Autocomplete
                    label="Tag"
                    placeholder="Select or enter tag"
                    data={tags}
                    filter={optionsFilter}
                    mb="xs"
                    {...form.getInputProps("tag")}
                />
                <Flex justify="end" gap="xs">
                    <Button variant="light" color="gray" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        color="blue"
                        /* disabled={!form.isValid()} */
                        onClick={handleCreate}
                    >
                        Create
                    </Button>
                </Flex>
            </Stack>
        </Modal>
    );
};

const optionsFilter: OptionsFilter = ({options, search}) => {
    const isOption = (options as ComboboxItem[]).some((option) => {
        return option.value.toLowerCase() === search.toLowerCase();
    });
    const filtered = (options as ComboboxItem[]).filter((option) => {
        return option.label.toLowerCase().includes(search.toLowerCase());
    });
    return isOption ? options : filtered;
};

export default NewPromptModal;
