import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {MantineProvider, Stack} from "@mantine/core";
import {ModalsProvider} from "@mantine/modals";
import {store} from "./app/store";
import theme from "./app/theme";
import App from "./app/App.tsx";
import Chat from "./features/chat/components/Chat.tsx";
import PromptBuilder from "./features/prompt-builder/components/PromptBuilder.tsx";
import {TestBuilder} from "./features/promptfoo/test-builder/TestEditor.tsx";
import {TestsuiteEditor} from "./features/promptfoo/test-runner/TestsuiteEditor.tsx";
import {TestRunner} from "./features/promptfoo/test-runner/TestRunner.tsx";
import {TestLibrary} from "./features/promptfoo/test-builder/TestLibrary.tsx";
import ChatSettings from "./features/chat-settings/components/ChatSettings.tsx";
import {TestResultOverview} from "./features/promptfoo/test-result/TestResultOverview.tsx";
import TestRunViewer from "./features/promptfoo/test-result/TestRunViewer.tsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: (
                    <Stack h="100%">
                        <ChatSettings />
                        <Chat />
                    </Stack>
                ),
            },
            {
                path: "/prompt-builder",
                element: <PromptBuilder />,
            },
            {
                path: "/prompt-builder/:promptId",
                element: <PromptBuilder />,
            },
            {
                path: "/test-builder",
                element: <TestLibrary />,
            },
            {
                path: "/test-builder/editor/:testId",
                element: <TestBuilder />,
            },
            {
                path: "/test-builder/editor/new",
                element: <TestBuilder />,
            },
            {
                path: "/test-runner",
                element: <TestRunner />,
            },
            {
                path: "/test-runner/result/:testsuiteId",
                element: <TestResultOverview />,
            },
            {
                path: "/test-runner/result/:testsuiteId/:testRunId",
                element: <TestRunViewer />,
            },
            {
                path: "/test-runner/editor/:testsuiteId",
                element: <TestsuiteEditor />,
            },
            {
                path: "/test-runner/editor/new",
                element: <TestsuiteEditor />,
            },
        ],
    },
]);

createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <Provider store={store}>
            <MantineProvider theme={theme}>
                <ModalsProvider>
                    <RouterProvider router={router} />
                </ModalsProvider>
            </MantineProvider>
        </Provider>
    </StrictMode>
);
