import {
    ActionIcon,
    Button,
    Group,
    Stack,
    Table,
    Text,
    Title,
} from "@mantine/core";
import {useLocalStorage} from "@mantine/hooks";
import {TestSuiteEvaluation} from "../../../types";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import IconArrowLeft from "../../../assets/arrow-left.svg?react";
import {testApi} from "../testApi";
import {Scrollbox} from "../shared/Scrollbox";
import {formatTestRunId, formatTimeAgo} from "../test-runner/TestRunner";
import IconCalendarClock from "../../../assets/calendar-clock.svg?react";
import IconReceipt from "../../../assets/receipt.svg?react";

export const TestResultOverview = () => {
    const navigate = useNavigate();
    const {testsuiteId} = useParams();
    const [testruns] = useLocalStorage<TestSuiteEvaluation[]>({
        key: "testruns",
        defaultValue: [],
    });
    const testsuiteQuery = testApi.endpoints.getTestSuiteById.useQuery(
        testsuiteId ?? "",
        {
            skip: !testsuiteId,
        }
    );
    const testsuiteRuns = testruns
        .filter((run) => run.testsuiteId === testsuiteId)
        .sort((a, b) => b.createdAt - a.createdAt);

    return (
        <Scrollbox w="100%" p="xl">
            <Group gap="xs" flex={1} mb="xl">
                <ActionIcon
                    color="gray"
                    variant="subtle"
                    radius="xl"
                    onClick={() => navigate(-1)}
                >
                    <IconArrowLeft />
                </ActionIcon>
                <Title order={1} size="xl">
                    {testsuiteQuery.data?.meta.name}
                </Title>
            </Group>
            <Table
                horizontalSpacing="md"
                verticalSpacing="xs"
                miw={300}
                layout="fixed"
            >
                <Table.Tbody>
                    <Table.Tr>
                        <Table.Th>Description</Table.Th>
                        <Table.Th>Passes</Table.Th>
                        <Table.Th>Time</Table.Th>
                    </Table.Tr>
                    {testsuiteRuns.map((run) => {
                        return (
                            <>
                                <Table.Tr key={run.id}>
                                    <Table.Td>
                                        <Button
                                            variant="subtle"
                                            component={NavLink}
                                            to={`/test-runner/result/${testsuiteId}/${run.id}`}
                                        >
                                            <Group gap="xs">
                                                <Text>
                                                    {formatTestRunId(
                                                        run.id,
                                                        testsuiteRuns
                                                    )}
                                                </Text>
                                                <Text c="dimmed" size="sm">
                                                    {}
                                                </Text>
                                            </Group>
                                        </Button>
                                    </Table.Td>
                                    <Table.Td>
                                        <Text>
                                            {run.results.reduce(
                                                (acc, result) =>
                                                    acc +
                                                    (result.success ? 1 : 0),
                                                0
                                            )}{" "}
                                            / {run.results.length}
                                        </Text>
                                    </Table.Td>
                                    <Table.Td>
                                        <Stack gap="xs">
                                            <Group gap="xs">
                                                <IconCalendarClock
                                                    style={{
                                                        height: "1rem",
                                                        width: "1rem",
                                                    }}
                                                />
                                                <Text>
                                                    {formatTimeAgo(
                                                        run.createdAt
                                                    )}
                                                </Text>
                                            </Group>
                                            <Group gap="xs">
                                                <IconReceipt
                                                    style={{
                                                        height: "1rem",
                                                        width: "1rem",
                                                    }}
                                                />
                                                <Text>
                                                    {run.results
                                                        .reduce(
                                                            (acc, result) =>
                                                                acc +
                                                                result.cost,
                                                            0
                                                        )
                                                        .toFixed(6)}
                                                </Text>
                                            </Group>
                                        </Stack>
                                    </Table.Td>
                                </Table.Tr>
                            </>
                        );
                    })}
                </Table.Tbody>
            </Table>
        </Scrollbox>
    );
};
