import React, {useState} from "react";
import classes from "./UserMessage.module.css";
import {Avatar, Button, Tooltip, Textarea, Flex} from "@mantine/core";
import IconPencil from "../../../assets/pencil.svg?react";

interface Props {
    content: string;
    onResend: (message: string) => void;
}

const UserMessage: React.FC<Props> = ({content, onResend}) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [value, setValue] = useState<string>(content);

    const handleCancel = () => {
        setEdit(false);
        setValue(content);
    };

    const handleResend = () => {
        setEdit(false);
        onResend(value);
    };

    return (
        <div className={classes.root}>
            <Avatar color="orange" mr="md" />
            {edit ? (
                <div className={classes.edit}>
                    <Textarea
                        value={value}
                        variant="unstyled"
                        autosize
                        size="md"
                        p="xs"
                        styles={{input: {padding: 0}}}
                        onChange={(e) => setValue(e.target.value)}
                    />
                    <Flex justify="end" gap="4px" p="xs" pt="0">
                        <Button color="gray" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button disabled={value === ""} onClick={handleResend}>
                            Send
                        </Button>
                    </Flex>
                </div>
            ) : (
                <>
                    <div className={classes.content}>{content}</div>
                    <Tooltip label="Edit message">
                        <Button
                            className={classes.hidden}
                            variant="message-button"
                            color="gray"
                            size="xs"
                            mt="calc(0.25rem + 2px)"
                            ml="0.25rem"
                            flex="0 0 auto"
                            onClick={() => setEdit(true)}
                        >
                            <IconPencil height="18px" />
                        </Button>
                    </Tooltip>
                </>
            )}
        </div>
    );
};

export default UserMessage;
