import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {TestFormValuesType} from "./test-builder/TestEditor";
import {AITest, PaginatedResponse, TestSuite} from "../../types";

const isMultiValue = (type: string) => {
    return (
        type === "icontains-all" ||
        type === "icontains-any" ||
        type === "contains-all" ||
        type === "contains-any"
    );
};

export const testApi = createApi({
    reducerPath: "testApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api",
        prepareHeaders: (headers) => {
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    tagTypes: ["Test", "TestSuite"],
    endpoints: (builder) => ({
        // Create a new Test
        createTest: builder.mutation<TestFormValuesType, TestFormValuesType>({
            query: (newTest) => ({
                url: "Test/create",
                method: "POST",
                body: serializeAssertValue(newTest),
            }),
            invalidatesTags: ["Test"],
        }),

        // Get a Test by ID
        getTestById: builder.query<AITest, string>({
            query: (id) => `Test/${id}`,
            providesTags: (result, error, id) => [{type: "Test", id}],
            transformResponse: (response: AITest) => {
                return deserializeAssertValue(response);
            },
        }),

        // Get all Tests
        getAllTests: builder.query<
            PaginatedResponse<AITest>,
            {pageSize: number; page: number}
        >({
            query: ({pageSize, page}) =>
                `Test?pageSize=${pageSize}&pageNumber=${page}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.items.map(({meta}) => ({
                              type: "Test" as const,
                              id: meta.id,
                          })),
                          {type: "Test", id: "LIST"},
                      ]
                    : [{type: "Test", id: "LIST"}],
            transformResponse: (response: PaginatedResponse<AITest>) => {
                return {
                    ...response,
                    items: response.items.map(deserializeAssertValue),
                };
            },
        }),
        // New endpoint for getting multiple tests by IDs
        getTestsByIds: builder.query<AITest[], string[]>({
            query: (ids) => ({
                url: "Test/batch",
                method: "GET",
                params: {ids},
            }),
            transformResponse: (response: AITest[]) => {
                return response.map(deserializeAssertValue);
            },
            providesTags: (result) =>
                result
                    ? result.map((test) => ({
                          type: "Test" as const,
                          id: test.meta.id,
                      }))
                    : [],
        }),
        // Update a Test
        updateTest: builder.mutation<
            void,
            {id: string; updatedTest: TestFormValuesType}
        >({
            query: ({id, updatedTest}) => ({
                url: `Test/${id}`,
                method: "PUT",
                body: serializeAssertValue(updatedTest),
            }),
            invalidatesTags: (result, error, {id}) => [{type: "Test", id}],
        }),

        // Delete a Test
        deleteTest: builder.mutation<void, string>({
            query: (id) => ({
                url: `Test/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, id) => [{type: "Test", id}],
        }),
        // TestSuite endpoints
        createTestSuite: builder.mutation<TestSuite, TestSuite>({
            query: (newTestSuite) => ({
                url: "TestSuite/create",
                method: "POST",
                body: newTestSuite,
            }),
            invalidatesTags: ["TestSuite"],
        }),

        getTestSuiteById: builder.query<TestSuite, string>({
            query: (id) => `TestSuite/${id}`,
            providesTags: (result, error, id) => [{type: "TestSuite", id}],
        }),

        getAllTestSuites: builder.query<
            PaginatedResponse<TestSuite>,
            {pageSize: number; page: number}
        >({
            query: ({pageSize, page}) =>
                `TestSuite?pageSize=${pageSize}&pageNumber=${page}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.items.map(({meta}) => ({
                              type: "TestSuite" as const,
                              id: meta.id,
                          })),
                          {type: "TestSuite", id: "LIST"},
                      ]
                    : [{type: "TestSuite", id: "LIST"}],
        }),

        updateTestSuite: builder.mutation<
            void,
            {id: string; updatedTestSuite: TestSuite}
        >({
            query: ({id, updatedTestSuite}) => ({
                url: `TestSuite/${id}`,
                method: "PUT",
                body: updatedTestSuite,
            }),
            invalidatesTags: (result, error, {id}) => [{type: "TestSuite", id}],
        }),

        deleteTestSuite: builder.mutation<void, string>({
            query: (id) => ({
                url: `TestSuite/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, id) => [{type: "TestSuite", id}],
        }),
    }),
});

// Export hooks for usage in functional components
export const {
    useCreateTestMutation,
    useGetTestByIdQuery,
    useGetAllTestsQuery,
    useUpdateTestMutation,
    useDeleteTestMutation,
} = testApi;

const serializeAssertValue = (test: TestFormValuesType) => {
    return {
        ...test,
        asserts: test.asserts.map((assert) => {
            if (isMultiValue(assert.type)) {
                return {
                    ...assert,
                    value: JSON.stringify(assert.value),
                };
            }
            return assert;
        }),
    };
};

const deserializeAssertValue = (test: AITest): AITest => {
    return {
        ...test,
        asserts: test.asserts.map((assert) => {
            if (isMultiValue(assert.type)) {
                return {...assert, value: JSON.parse(assert.value)};
            }
            return assert;
        }),
    };
};
