import React, {useRef} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {Editor as TinyMCEEditor} from "tinymce";
import {Box, Flex, InputLabel} from "@mantine/core";
import setup from "./variable";
import PromptGenerator from "../PromptGenerator";

interface Props {
    initialValue: string;
    onChange: (content: string, htmlContent: string) => void;
}

const PromptEditor: React.FC<Props> = ({initialValue, onChange}) => {
    const ref = useRef<TinyMCEEditor>();

    /*  const [generatePrompt, {isLoading}] = useGeneratePromptMutation(); */

    const handleGenerate = (content: string) => {
        if (ref.current) {
            const editor = ref.current;
            editor.insertContent(content);
        }
    };

    return (
        <Box h="50%">
            <Flex justify="space-between" mb="6px">
                <InputLabel size="md">Prompt Editor</InputLabel>
                <PromptGenerator onGenerate={handleGenerate} />
            </Flex>
            <Editor
                initialValue={initialValue}
                tinymceScriptSrc="/tinymce/tinymce.min.js"
                licenseKey="gpl"
                plugins="codesample code"
                init={{
                    menubar: false,
                    statusbar: false,
                    toolbar: false,
                    resize: true,
                    height: "90%",
                    /* min_height: 500, */
                    /* codesample_languages: [
                    {text: "HTML/XML", value: "markup"},
                    {text: "JavaScript", value: "javascript"},
                    {text: "CSS", value: "css"},
                    {text: "PHP", value: "php"},
                    {text: "Ruby", value: "ruby"},
                    {text: "Python", value: "python"},
                    {text: "Java", value: "java"},
                    {text: "C", value: "c"},
                    {text: "C#", value: "csharp"},
                    {text: "C++", value: "cpp"},
                ], */
                    /* toolbar: "code codesample", */
                    /* codesample_global_prismjs: true, */
                    highlight_on_focus: false,
                    newline_behavior: "linebreak",
                    content_style: `
                    .mce-content-body {
                        font-size: 0.9rem;
                        line-height: 1.7;
                    }
                    .variable { 
                        background: rgba(34, 139, 230, 0.2);
                        border-radius: 8px; 
                        outline: 2px solid rgba(34, 139, 230, 0.5);
                        padding: 2px 4px;
                        margin: 0 1px;
                    }
                `,
                    inline_boundaries_selector: "span.variable",
                    noneditable_class: "variable",

                    setup,
                }}
                onInit={(_, editor) => {
                    ref.current = editor;
                }}
                onEditorChange={(htmlContent, editor) => {
                    /* const content = editor.getContent({format: "text"}); */
                    const content = editor.getContent();
                    onChange(content, htmlContent);
                }}
            />
        </Box>
    );
};

export default PromptEditor;
