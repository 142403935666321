import {
    Fieldset,
    Text,
    Group,
    Button,
    TextInput,
    Textarea,
    ActionIcon,
} from "@mantine/core";
import {UseFormReturnType} from "@mantine/form";
import {v4 as uuid} from "uuid";
import IconTrash from "../../../assets/trash.svg?react";
import {TestFormValuesType} from "./TestEditor";

type VarsSectionProps = {
    form: UseFormReturnType<TestFormValuesType>;
};

export const VarsSection = ({form}: VarsSectionProps) => (
    <Fieldset legend="Variables" variant="unstyled">
        <Text c="dimmed" mb="md">
            Key-value pairs to substitute in the prompt
        </Text>
        {form.getValues().vars.map((item, index) => (
            <Group key={item.key} mt="xs" align="flex-start">
                <TextInput
                    label="Name"
                    placeholder="Var name"
                    autoComplete="off"
                    flex={1}
                    key={form.key(`vars.${index}.name`)}
                    {...form.getInputProps(`vars.${index}.name`)}
                />
                <Textarea
                    label="Value"
                    variant="filled"
                    resize="vertical"
                    autosize
                    maxRows={5}
                    placeholder="Var value"
                    flex={3}
                    key={form.key(`vars.${index}.value`)}
                    {...form.getInputProps(`vars.${index}.value`)}
                />
                <ActionIcon
                    color="red"
                    mt={"1.5rem"}
                    onClick={() => {
                        if (index > 0) form.removeListItem("vars", index);
                    }}
                >
                    <IconTrash style={{height: "1rem", width: "1rem"}} />
                </ActionIcon>
            </Group>
        ))}
        <Group justify="center" mt="md">
            <Button
                onClick={() =>
                    form.insertListItem("vars", {
                        id: "",
                        name: "",
                        value: "",
                        key: uuid(),
                    })
                }
            >
                Add var
            </Button>
        </Group>
    </Fieldset>
);
