import classes from "./ReferencesBlock.module.css";
import ExternalLink from "../../../../../assets/external-link.svg?react";

type ReferenceItem = {
    id: string;
    content: string;
    href: string;
};
export const ReferenceItem = ({id, content, href}: ReferenceItem) => {
    return (
        <li id={`reference${id}`}>
            <a href={href} className={classes.link}>
                <span>{content}</span>
                <ExternalLink className={classes.icon} />
            </a>
        </li>
    );
};

type ReferencesBlockProps = {
    items: ReferenceItem[];
};
export const ReferencesBlock = ({items}: ReferencesBlockProps) => {
    return (
        <section>
            <h2>Referanser</h2>
            <ol className={classes.ol}>
                {items.map((reference) => (
                    <ReferenceItem
                        key={reference.id}
                        id={reference.id}
                        href={reference.href}
                        content={reference.content}
                    />
                ))}
            </ol>
        </section>
    );
};
