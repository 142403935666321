import {Code, Tooltip} from "@mantine/core";
import classes from "./TestRunnerEditor.module.css";

type VariableDisplayProps = {
    variable: string;
    variablesToTestAgainst?: Set<string>;
    affirmativeTooltip?: string;
    negativeTooltip?: string;
    variant?: "default" | "padded";
};
export const VariableDisplay = ({
    variable,
    variablesToTestAgainst,
    affirmativeTooltip,
    negativeTooltip,
    variant = "default",
}: VariableDisplayProps) => {
    const hasVariable = variablesToTestAgainst?.has(variable) ?? false;

    return (
        <Tooltip
            withArrow
            arrowSize={4}
            position="top"
            label={hasVariable ? affirmativeTooltip : negativeTooltip}
        >
            <Code
                key={variable}
                className={
                    variant === "default"
                        ? classes.variable
                        : classes.variablePadded
                }
                c={hasVariable ? "green" : "red"}
            >
                {variable}
            </Code>
        </Tooltip>
    );
};
