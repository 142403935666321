import {Fieldset, Text, Group, Button, Card} from "@mantine/core";
import {UseFormReturnType} from "@mantine/form";
import {v4 as uuid} from "uuid";
import {AssertionItem} from "./AssertionItem";
import {TestFormValuesType} from "./TestEditor";

type AssertsSectionProps = {
    form: UseFormReturnType<TestFormValuesType>;
};

export const AssertsSection = ({form}: AssertsSectionProps) => (
    <Fieldset legend="Assertions" variant="unstyled">
        <Text c="dimmed" mb="md">
            List of automatic checks to run on the LLM output
        </Text>
        <Group align="flex-start">
            {form.getValues().asserts.map((item, index) => (
                <AssertionItem
                    key={item.key}
                    item={item}
                    index={index}
                    form={form}
                />
            ))}
        </Group>
        <Group justify="center" mt="md">
            <Button
                onClick={() =>
                    form.insertListItem("asserts", {
                        id: "",
                        type: "",
                        value: "",
                        weight: 1,
                        key: uuid(),
                    })
                }
            >
                Add assertion
            </Button>
        </Group>
    </Fieldset>
);
