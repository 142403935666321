import {Popover, Button, Textarea, Flex, Select} from "@mantine/core";
import {useGetPromptsQuery, useOpenaiCompletionMutation} from "../promptApi";
import IconSparkles from "../../../assets/sparkles.svg?react";
import {useState} from "react";

interface Props {
    onGenerate: (content: string, htmlContent: string) => void;
}

const PromptGenerator: React.FC<Props> = ({onGenerate}) => {
    const [openaiCompletion, {isLoading}] = useOpenaiCompletionMutation();
    const prompts = useGetPromptsQuery();
    const defaultPrompt = prompts.data?.find(
        (p) => p.tag === "prompt generator"
    );
    const [prompt, setPrompt] = useState<string>("");
    const [promptId, setPromptId] = useState<string>(defaultPrompt?.id ?? "");
    const [outputFormat, setOutputFormat] = useState<string>("text");

    const handleGenerate = async () => {
        const response = await openaiCompletion({
            model: "gpt-4o",
            messages: [
                {
                    role: "system",
                    content:
                        prompts.data?.find((p) => p.id === promptId)?.content ??
                        "",
                },
                {role: "user", content: prompt},
            ],
        });
        console.log(response);

        if (response.data) {
            onGenerate(response.data.choices[0].message.content, "");
        }
    };

    return (
        <Popover
            width={460}
            position="bottom-end"
            shadow="md"
            radius="md"
            trapFocus
        >
            <Popover.Target>
                <Button
                    variant="subtle"
                    color="black"
                    size="xs"
                    leftSection={<IconSparkles />}
                >
                    Generate
                </Button>
            </Popover.Target>
            <Popover.Dropdown p="xs">
                <Textarea
                    variant="unstyled"
                    placeholder="What would you like to generate?"
                    autosize
                    minRows={3}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                />
                <Flex justify="flex-end" gap="xs">
                    <Select
                        data={prompts.data?.map((p) => ({
                            label: p.name,
                            value: p.id,
                        }))}
                        placeholder="Select prompt"
                        size="xs"
                        value={promptId}
                        onChange={(value) => setPromptId(value ?? "")}
                    />
                    <Select
                        data={["text", "json", "markdown", "html"]}
                        placeholder="Select output format"
                        size="xs"
                        value={outputFormat}
                        onChange={(value) => setOutputFormat(value ?? "text")}
                    />
                    <Button loading={isLoading} onClick={handleGenerate}>
                        Create
                    </Button>
                </Flex>
            </Popover.Dropdown>
        </Popover>
    );
};

export default PromptGenerator;
