import {Editor, TinyMCE, AstNode} from "tinymce";
import {store} from "../../../../app/store";
import {promptApi} from "../../promptApi";

declare const tinymce: TinyMCE;

type Attrs = Record<string, string>;

const setupVariableAutocompleter = (editor: Editor) => {
    editor?.ui.registry.addAutocompleter("variables", {
        trigger: "{",
        minChars: 0,
        onAction: (autocompleteApi, rng, value) => {
            const selectPrompts = promptApi.endpoints.getPrompts.select();
            const {data} = selectPrompts(store.getState());
            const prompt = data?.find((p) => p.id === value);
            editor.selection.setRng(rng);
            editor.insertContent(
                `<span class="variable" data-variable-id="${value}">${prompt?.name}</span>`
            );
            autocompleteApi.hide();
        },
        fetch: async (pattern) => {
            const selectPrompts = promptApi.endpoints.getPrompts.select();
            const {data} = selectPrompts(store.getState());

            const variables =
                data
                    ?.filter((p) => p.tag === "variable")
                    ?.filter((p) =>
                        p.name.toLowerCase().includes(pattern.toLowerCase())
                    ) || [];

            return variables.map((variable) => ({
                type: "autocompleteitem",
                text: variable.name,
                value: variable.id,
            }));
        },
    });
};
const createNode = (name: string, attrs: Attrs = {}): AstNode => {
    return tinymce.html.Node.create(name, attrs);
};

const filterContent = (editor: Editor) => {
    editor.on("PreInit", () => {
        const {serializer} = editor;

        // This will replace variable spans with {{variableId}} from the output
        serializer.addNodeFilter("span", (nodes) => {
            for (let i = 0; i < nodes.length; i += 1) {
                const node = nodes[i];

                if (node.attr("class") === "variable") {
                    const variableId = node.attr("data-variable-id");
                    const text = createNode("#text");
                    text.value = `{{${variableId}}}`;
                    node.replace(text);
                }
            }
        });

        // This will remove the <p> tags from the output
        serializer.addNodeFilter("p", (nodes) => {
            for (let i = 0; i < nodes.length; i += 1) {
                const node = nodes[i];
                node.unwrap();
            }
        });
    });
};

// This will replace {{variableId}} with the variable spans
export const setupVariableParser = (editor: Editor) => {
    editor.on("BeforeSetContent", (e) => {
        const selectPrompts = promptApi.endpoints.getPrompts.select();
        const {data} = selectPrompts(store.getState());

        e.content = e.content.replace(/{{(.*?)}}/g, (match, variableId) => {
            const variable = data?.find((p) => p.id === variableId);

            console.log(variable, data);

            if (variable) {
                return `<span class="variable" data-variable-id="${variableId}">${variable?.name}</span>`;
            }

            return match;
        });

        /*  editor.setContent(withVariables); */
    });
};

const setup = (editor: Editor) => {
    setupVariableAutocompleter(editor);
    setupVariableParser(editor);
    filterContent(editor);
};

export default setup;
