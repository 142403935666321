import {useMemo} from "react";
import classes from "./Citation.module.css";

type LinkProps = {
    reference: string;
};
export const Citation = ({reference}: LinkProps) => {
    const number = useMemo(() => {
        let number = reference.replace(/[\[\]]/g, "");
        try {
            parseInt(number);
            return number;
        } catch (e) {
            console.error("Invalid reference number");
            return null;
        }
    }, [reference]);

    if (!number) {
        return null;
    }

    return (
        <a href={`#reference${number}`} className={classes.link}>
            <sup style={{color: "blue"}}>({number})</sup>
        </a>
    );
};
