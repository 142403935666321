import {AssertionType} from "../../../types";

export const assertionTypes: AssertionType[] = [
    "answer-relevance",
    "classifier",
    "contains-all",
    "contains-any",
    "contains-json",
    "contains-sql",
    "contains-xml",
    "contains",
    "context-faithfulness",
    "context-recall",
    "context-relevance",
    "cost",
    "equals",
    "factuality",
    "human",
    "icontains-all",
    "icontains-any",
    "icontains",
    "is-json",
    "is-sql",
    "is-valid-openai-function-call",
    "is-valid-openai-tools-call",
    "is-xml",
    "javascript",
    "latency",
    "levenshtein",
    "llm-rubric",
    "model-graded-closedqa",
    "model-graded-factuality",
    "moderation",
    "perplexity-score",
    "perplexity",
    "python",
    "regex",
    "rouge-l",
    "rouge-n",
    "rouge-s",
    "select-best",
    "similar",
    "starts-with",
    "webhook",
];

export const assertionSelectGroups = [
    {
        group: "General",
        items: [
            {value: "icontains", label: "icontains"},
            {value: "llm-rubric", label: "llm-rubric"},
        ],
    },
    {
        group: "Exact Matches",
        items: [
            {value: "contains", label: "contains"},
            {value: "equals", label: "equals"},
            {value: "starts-with", label: "starts-with"},
            {value: "regex", label: "regex"},
        ],
    },
    {
        group: "Structued Data check",
        items: [
            {value: "contains-json", label: "contains-json"},
            {value: "contains-sql", label: "contains-sql"},
            {value: "contains-xml", label: "contains-xml"},
            {value: "is-json", label: "is-json"},
            {value: "is-sql", label: "is-sql", disabled: true},
            {value: "is-xml", label: "is-xml"},
        ],
    },
    {
        group: "Multi-Substring Matches",
        items: [
            {value: "icontains-all", label: "icontains-all"},
            {value: "icontains-any", label: "icontains-any"},
            {value: "contains-all", label: "contains-all"},
            {value: "contains-any", label: "contains-any"},
        ],
    },
    {
        group: "Evaluation metrics",
        items: [
            {value: "answer-relevance", label: "answer-relevance"},
            {value: "context-faithfulness", label: "context-faithfulness"},
            {value: "context-recall", label: "context-recall"},
            {value: "context-relevance", label: "context-relevance"},
            {value: "classifier", label: "classifier", disabled: true},
        ],
    },
    {
        group: "Numeric",
        items: [
            {value: "cost", label: "cost"},
            {value: "latency", label: "latency"},
            {value: "perplexity-score", label: "perplexity-score"},
            {value: "perplexity", label: "perplexity"},
            {value: "levenshtein", label: "levenshtein", disabled: true},
            {value: "rouge-n", label: "rouge-n"},
        ],
    },
    {
        group: "Code",
        items: [
            {value: "python", label: "python"},
            {value: "javascript", label: "javascript"},
        ],
    },
];

export const assertionSelectData = [
    {value: "answer-relevance", label: "answer-relevance"},
    {value: "classifier", label: "classifier"},
    {value: "contains-all", label: "contains-all"},
    {value: "contains-any", label: "contains-any"},
    {value: "contains-json", label: "contains-json"},
    {value: "contains-sql", label: "contains-sql"},
    {value: "contains-xml", label: "contains-xml"},
    {value: "contains", label: "contains"},
    {value: "context-faithfulness", label: "context-faithfulness"},
    {value: "context-recall", label: "context-recall"},
    {value: "context-relevance", label: "context-relevance"},
    {value: "cost", label: "cost"},
    {value: "equals", label: "equals"},
    {value: "factuality", label: "factuality"},
    {value: "human", label: "human"},
    {value: "icontains-all", label: "icontains-all"},
    {value: "icontains-any", label: "icontains-any"},
    {value: "icontains", label: "icontains"},
    {value: "is-json", label: "is-json"},
    {value: "is-sql", label: "is-sql", disabled: true},
    {
        value: "is-valid-openai-function-call",
        label: "is-valid-openai-function-call",
    },
    {value: "is-valid-openai-tools-call", label: "is-valid-openai-tools-call"},
    {value: "is-xml", label: "is-xml"},
    {value: "javascript", label: "javascript"},
    {value: "latency", label: "latency"},
    {value: "levenshtein", label: "levenshtein"},
    {value: "llm-rubric", label: "llm-rubric"},
    {value: "model-graded-closedqa", label: "model-graded-closedqa"},
    {value: "model-graded-factuality", label: "model-graded-factuality"},
    {value: "moderation", label: "moderation"},
    {value: "perplexity-score", label: "perplexity-score"},
    {value: "perplexity", label: "perplexity"},
    {value: "python", label: "python"},
    {value: "regex", label: "regex"},
    {value: "rouge-l", label: "rouge-l"},
    {value: "rouge-n", label: "rouge-n"},
    {value: "rouge-s", label: "rouge-s"},
    {value: "select-best", label: "select-best"},
    {value: "similar", label: "similar"},
    {value: "starts-with", label: "starts-with"},
    {value: "webhook", label: "webhook"},
];

export const assertionTypeDescriptions: Record<string, string> = {
    "answer-relevance": "Ensures LLM output is relevant to the input query.",
    classifier:
        "Evaluates output against a classifier’s prediction or criteria.",
    "contains-all":
        "Checks if all specified keywords are present in the output.",
    "contains-any":
        "Checks if at least one of the specified keywords is in the output.",
    "contains-json": "Verifies that the output contains valid JSON.",
    "contains-sql": "Checks if the output contains SQL syntax.",
    "contains-xml": "Checks if the output includes XML format.",
    contains: "Determines if a specific substring exists in the output.",
    "context-faithfulness":
        "Ensures the output accurately reflects the given context.",
    "context-recall":
        "Validates that critical information from the context is present in the output.",
    "context-relevance":
        "Assesses if the context used is pertinent to the query.",
    cost: "Monitors the cost associated with generating the output.",
    equals: "Tests if the output exactly matches the expected value.",
    factuality:
        "Verifies the factual accuracy of the LLM output against known truths.",
    human: "Human-in-the-loop grading for quality and accuracy.",
    "icontains-all":
        "Case-insensitive check for all specified keywords in output.",
    "icontains-any":
        "Case-insensitive check for any specified keywords in output.",
    icontains: "Performs a case-insensitive search for a substring in output.",
    "is-json": "Checks if the output is valid JSON format.",
    "is-sql": "Verifies if the output matches SQL format.",
    "is-valid-openai-function-call":
        "Confirms output matches OpenAI’s function call format.",
    "is-valid-openai-tools-call":
        "Ensures output aligns with OpenAI tools call structure.",
    "is-xml": "Tests if output is in XML format.",
    javascript: "Uses custom JavaScript code to evaluate the output.",
    latency: "Measures the response time of the LLM output.",
    levenshtein:
        "Calculates Levenshtein distance between output and expected value.",
    "llm-rubric": "LLM-based grading rubric for complex evaluation criteria.",
    "model-graded-closedqa":
        "Evaluates answer quality in a closed question format.",
    "model-graded-factuality":
        "Grading of LLM output for factual accuracy by a model.",
    moderation: "Checks for any policy-violating content in the output.",
    "perplexity-score":
        "Measures the perplexity of generated text for coherence.",
    perplexity: "Assesses the overall perplexity or uncertainty in the output.",
    python: "Executes a Python script to validate the LLM output.",
    regex: "Uses regex patterns to check if output matches expected structure.",
    "rouge-l":
        "Calculates ROUGE-L score for similarity between output and reference.",
    "rouge-n":
        "Evaluates n-gram overlap for text similarity with the reference.",
    "rouge-s": "Uses ROUGE-S (skip-bigram) for sequence-based similarity.",
    "select-best": "Chooses the best output among several options.",
    similar: "Tests similarity between output and reference text.",
    "starts-with": "Checks if output begins with a specified string.",
    webhook: "Sends output to an external service for validation.",
};
