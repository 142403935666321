import {Box, BoxProps} from "@mantine/core";
import classes from "./Scrollbox.module.css";

type ScrollBoxProps = {
    children: React.ReactNode;
} & BoxProps;
export const Scrollbox = ({children, ...props}: ScrollBoxProps) => (
    <Box className={classes.scrollbox} {...props}>
        {children}
    </Box>
);
