import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, LoadingOverlay, rem, Select, Text} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {useGetPromptsQuery} from "../promptApi";
import PromptForm from "./PromptForm";
import NewPromptModal from "./NewPromptModal";
import IconPlayListAdd from "../../../assets/playlist-add.svg?react";
import classes from "./PromptBuilder.module.css";

const PromptBuilder: React.FC = () => {
    const {data, isLoading} = useGetPromptsQuery();
    const [opened, {open, close}] = useDisclosure(false);
    const navigate = useNavigate();
    const {promptId} = useParams();
    const s = data?.filter((p) => p.tag === "system");
    const prompt = data?.find((p) => p.id === promptId) || s?.[0];
    const tags = Array.from(new Set(data?.map((p) => p.tag) || []));

    const prompts = tags.map((tag) => ({
        group: tag,
        items:
            data
                ?.filter((p) => p.tag === tag)
                .map((p) => ({value: p.id, label: p.name})) || [],
    }));

    return (
        <div className={classes.grid}>
            <div className={classes.title}>
                <Text fw={500} size="md">
                    Prompt Builder
                </Text>
                <Button
                    variant="light"
                    size="xs"
                    leftSection={
                        <IconPlayListAdd
                            style={{
                                width: rem(16),
                                height: rem(16),
                            }}
                        />
                    }
                    onClick={open}
                >
                    New Prompt
                </Button>
            </div>
            <div className={classes.sidebarTop}>
                <Select
                    value={prompt?.id}
                    data={prompts}
                    allowDeselect={false}
                    size="sm"
                    onChange={(id) => navigate(`/prompt-builder/${id}`)}
                />
            </div>
            {prompt && <PromptForm prompt={prompt} />}
            <LoadingOverlay visible={isLoading} loaderProps={{type: "dots"}} />
            <NewPromptModal opened={opened} onClose={close} />
        </div>
    );
};

export default PromptBuilder;
