import {
    Card,
    Group,
    Text,
    ActionIcon,
    Select,
    NumberInput,
    Tooltip,
    Box,
} from "@mantine/core";
import {UseFormReturnType} from "@mantine/form";
import IconTrash from "../../../assets/trash.svg?react";
import IconWeight from "../../../assets/weight.svg?react";
import {assertionSelectGroups, assertionTypeDescriptions} from "./data";
import classes from "./TestEditor.module.css";
import {TestFormValuesType} from "./TestEditor";
import {AssertionItemValue} from "./AssertionItemValue";

type AssertionItemProps = {
    form: UseFormReturnType<TestFormValuesType>;
    item: TestFormValuesType["asserts"][0];
    index: number;
};

export const AssertionItem = ({form, item, index}: AssertionItemProps) => (
    <Card
        key={item.key}
        withBorder
        radius="md"
        p="xl"
        miw={350}
        maw={350}
        className={classes.card}
    >
        <Card.Section className={classes.cardSection}>
            <Group align="center" justify="space-between">
                {/* <Text size="lg">{item.type ? item.type : "Choose type"}</Text> */}
                <Select
                    classNames={{
                        input: classes.selectInput,
                    }}
                    key={form.key(`asserts.${index}.type`)}
                    variant="unstyled"
                    rightSection={<></>}
                    placeholder="Choose type"
                    data={assertionSelectGroups}
                    {...form.getInputProps(`asserts.${index}.type`)}
                    comboboxProps={{
                        width: 350,
                    }}
                    maxDropdownHeight={400}
                    renderOption={(item) => (
                        <Box>
                            <Text size="sm">{item.option.label}</Text>
                            <Text size="xs" c="dimmed">
                                {assertionTypeDescriptions[item.option.value] ??
                                    ""}
                            </Text>
                        </Box>
                    )}
                />
                <ActionIcon
                    color="gray"
                    variant="subtle"
                    radius={"xl"}
                    onClick={() => {
                        form.removeListItem("asserts", index);
                    }}
                >
                    <IconTrash style={{height: "1rem", width: "1rem"}} />
                </ActionIcon>
            </Group>
            <Text size="sm" c="dimmed">
                {assertionTypeDescriptions[item.type] ?? "No description"}
            </Text>
        </Card.Section>
        <AssertionItemValue item={item} index={index} form={form} />
        <Tooltip
            label="The higher the weight, the more important the assertion is."
            position="top-start"
        >
            <NumberInput
                mt="sm"
                min={0.1}
                max={10}
                step={0.1}
                decimalScale={1}
                fixedDecimalScale
                classNames={{
                    input: classes.weightInput,
                }}
                variant="unstyled"
                aria-label="Weight of the assertion."
                style={{display: "inline-flex"}}
                leftSection={
                    <IconWeight
                        style={{height: "1.125rem", width: "1.125rem"}}
                    />
                }
                rightSection={<></>}
                key={form.key(`asserts.${index}.weight`)}
                {...form.getInputProps(`asserts.${index}.weight`)}
            />
        </Tooltip>
    </Card>
);
