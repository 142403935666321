import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {EvaluateTestSuite, TestSuiteEvaluation} from "../../types";

export const evaluationApi = createApi({
    reducerPath: "evaluationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/Testsuite",
        prepareHeaders: (headers) => {
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    tagTypes: ["TestSuiteEvaluation"],
    endpoints: (builder) => ({
        evaluateTestsuite: builder.mutation<
            TestSuiteEvaluation,
            EvaluateTestSuite
        >({
            query: (testsuite) => ({
                url: "/evaluate",
                method: "POST",
                body: JSON.stringify(testsuite),
            }),
        }),
    }),
});
