import {Middleware} from "redux";
import {isFulfilled, isRejected} from "@reduxjs/toolkit";
import {API_SUCCESS} from "./SessionKeeper";

export const responseStateMiddleware: Middleware = () => (next) => (action) => {
    if (isFulfilled(action)) {
        if (document) {
            document.dispatchEvent(
                new CustomEvent(API_SUCCESS, {detail: {success: true}})
            );
        }
    } else if (isRejected(action)) {
        if (
            //@ts-ignore
            "status" in action.payload &&
            action.payload?.status === 401 &&
            document
        ) {
            // Redirect to login page. Not sure if this is a good way to do it.

            const returnUrl = encodeURIComponent(
                window.location.pathname + window.location.search
            );

            window.location.href = "/auth/dib_login?returnUrl=" + returnUrl;
        }
    }

    return next(action);
};
