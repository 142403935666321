import {Citation} from "../inline/Citation";

type ParagraphProps = {
    children: React.ReactNode | string;
};

const processText = (text: string, _options?: {}) => {
    const regex = /\[\d+\]/g; // Matches patterns like [1], [2], etc.
    const parts = text.split(regex); // Split the text by the pattern
    const matches = text.match(regex); // Get all [1], [2], etc.

    return parts.reduce((acc: React.ReactNode[], part, index) => {
        acc.push(part); // Push normal text parts
        if (matches && matches[index]) {
            // If there’s a corresponding match, wrap it in <Reference>
            acc.push(<Citation key={index} reference={matches[index]} />);
        }
        return acc;
    }, []);
};

export const ParagraphBlock = ({children}: ParagraphProps) => {
    if (typeof children !== "string") {
        return <p>{children}</p>;
    } else {
        return <p>{processText(String(children))}</p>;
    }
};
