import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {chatApi} from "./chatApi";

interface Message {
    role: "system" | "user" | "assistant";
    content: string;
}

interface MessagesState {
    messages: Message[];
    isStreaming: boolean;
    promptId: string;
}

const initialState: MessagesState = {
    messages: [],
    isStreaming: false,
    promptId: "",
};

const chatSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        startAssistantMessage(state) {
            const lastMessage = state.messages[state.messages.length - 1];

            // If the last message is a previous assistant message, remove it
            if (lastMessage.role === "assistant") {
                state.messages.pop();
            }

            // Add an empty assistant message to the chat that can be streamed to
            state.messages.push({role: "assistant", content: ""});
            state.isStreaming = true;
        },
        finishAssistantMessage(state, action: PayloadAction<string>) {
            // Set the last assistant message to the finalized response
            const lastMessage = state.messages[state.messages.length - 1];

            if (lastMessage?.role === "assistant") {
                lastMessage.content = action.payload;
            }

            state.isStreaming = false;
        },
        setSystemPromptId(state, action: PayloadAction<string>) {
            state.promptId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            chatApi.endpoints.getChatCompletion.matchPending,
            (state, action) => {
                state.messages = action.meta.arg.originalArgs;
            }
        );
    },
});

export const {
    startAssistantMessage,
    finishAssistantMessage,
    setSystemPromptId,
} = chatSlice.actions;

export default chatSlice.reducer;
