import React, {useEffect, useRef, useState} from "react";
import {v4 as uuid} from "uuid";
import {useLazyGetChatCompletionQuery} from "../chatApi";
import {Box, Stack, Textarea} from "@mantine/core";
import {useAppSelector} from "../../../app/hooks";
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";
import {getHotkeyHandler} from "@mantine/hooks";
import classes from "./Chat.module.css";

const Chat: React.FC = () => {
    const {messages, isStreaming} = useAppSelector((state) => state.chat);
    const [userInput, setUserInput] = useState<string>("");
    const [getChatCompletion, {data}] = useLazyGetChatCompletionQuery();
    const viewport = useRef<HTMLDivElement>(null);
    const last = messages.length - 1;
    const {message, processes, sources} = data || {};

    const handleSubmit = async () => {
        getChatCompletion([
            ...messages,
            {role: "user", content: userInput, id: uuid()},
        ]);
        setUserInput("");
    };

    const handleRegenerate = (i: number) => {
        getChatCompletion([
            ...messages.slice(0, i - 1),
            {...messages[i - 1], id: uuid()},
        ]);
    };

    const handleResend = (value: string, i: number) => {
        getChatCompletion([
            ...messages.slice(0, i),
            {role: "user", content: value, id: uuid()},
        ]);
    };

    useEffect(() => {
        if (viewport.current) {
            viewport.current!.scrollTo({
                top: viewport.current!.scrollHeight,
                behavior: "smooth",
            });
        }
        // Todo: debounce
    }, [data]);

    return (
        <Stack h="100%" justify="space-between">
            <Box ref={viewport} className={classes.scrollbox}>
                {messages.map((msg, i) =>
                    msg.role === "user" ? (
                        <UserMessage
                            key={i}
                            content={msg.content}
                            onResend={(value) => handleResend(value, i)}
                        />
                    ) : (
                        <AssistantMessage
                            key={i}
                            content={
                                i === last
                                    ? message || msg.content
                                    : msg.content
                            }
                            isStreaming={isStreaming && i === last}
                            processes={i === last ? processes : undefined}
                            sources={i === last ? sources : undefined}
                            onRegenerate={() => handleRegenerate(i)}
                        />
                    )
                )}
            </Box>
            <Textarea
                classNames={{
                    input: classes.textarea,
                    wrapper: classes.textareaWrapper,
                }}
                variant="filled"
                placeholder="Ask your question here"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={getHotkeyHandler([["enter", handleSubmit]])}
                m="lg"
                radius="lg"
                size="md"
                autosize
                minRows={1}
                maxRows={13}
            />
        </Stack>
    );
};

export default Chat;
