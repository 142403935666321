import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {startAssistantMessage, finishAssistantMessage} from "./chatSlice";
import {systemPrompt} from "./components/query-analyzer/systemPrompt";
import {RootState} from "../../app/store";
import {
    isRagProcess,
    isRagSource,
    Message,
    RagProcess,
    RagSource,
} from "./types";

const openaiKey =
    "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";

interface ChatCompletion {
    message: string;
    processes?: RagProcess[];
    sources?: RagSource[];
}

export const chatApi = createApi({
    reducerPath: "chatApi",
    baseQuery: fetchBaseQuery({baseUrl: "/api/Chat"}),
    tagTypes: ["MessageStream"],
    endpoints: (builder) => ({
        getChatCompletion: builder.query<ChatCompletion, Message[]>({
            queryFn: () => ({
                data: {message: "", processes: undefined, sources: undefined},
            }), // We will handle the streaming in onCacheEntryAdded
            providesTags: ["MessageStream"],
            async onCacheEntryAdded(
                messages,
                {
                    updateCachedData,
                    cacheDataLoaded,
                    cacheEntryRemoved,
                    dispatch,
                    getState,
                }
            ) {
                const {chat} = getState() as RootState;
                dispatch(startAssistantMessage());

                try {
                    await cacheDataLoaded;

                    const controller = new AbortController();

                    const response = await fetch("/api/Chat/chatCompletion", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${openaiKey}`,
                        },
                        body: JSON.stringify({
                            model: "gpt-4o",
                            promptId: chat.promptId,
                            messages: [...messages],
                            stream: true,
                            temperature: 0.7,
                            max_tokens: 1500,
                            top_p: 0.95,
                            frequency_penalty: 0,
                            presence_penalty: 0,
                            stop: "None",
                        }),
                        signal: controller.signal,
                    });

                    if (!response.ok) {
                        throw new Error(
                            `Error ${response.status}: ${response.statusText}`
                        );
                    }

                    if (!response.body) {
                        throw new Error(
                            "ReadableStream not yet supported in this browser."
                        );
                    }

                    const reader = response.body.getReader();
                    const decoder = new TextDecoder("utf-8");
                    let done = false;
                    let accumulatedText = "";

                    while (!done) {
                        const {value, done: doneReading} = await reader.read();
                        done = doneReading;
                        const chunkValue = decoder.decode(value);

                        // Parse the SSE stream data
                        const lines = chunkValue
                            .split("\n")
                            .filter((line) => line.trim() !== "");

                        for (const line of lines) {
                            const message = line.replace(/^data: /, "");

                            if (message === "[DONE]") {
                                done = true;
                                break;
                            } else {
                                try {
                                    const parsed = JSON.parse(message);

                                    if ("choices" in parsed) {
                                        const content =
                                            parsed.choices[0]?.delta?.content;
                                        if (content) {
                                            accumulatedText += content;
                                            updateCachedData((draft) => {
                                                draft.message = accumulatedText;
                                            });
                                        }
                                    } else if (isRagProcess(parsed)) {
                                        updateCachedData((draft) => {
                                            draft.processes = draft.processes
                                                ? [...draft.processes, parsed]
                                                : [parsed];
                                        });
                                    } else if (isRagSource(parsed)) {
                                        updateCachedData((draft) => {
                                            draft.sources = draft.sources
                                                ? [...draft.sources, parsed]
                                                : [parsed];
                                        });
                                    }
                                } catch (error) {
                                    console.error(
                                        "Could not JSON parse stream message",
                                        message,
                                        error
                                    );
                                }
                            }
                        }
                    }

                    dispatch(finishAssistantMessage(accumulatedText));
                } catch (error) {
                    console.error("Error in streaming:", error);
                }

                await cacheEntryRemoved;
            },
        }),
        analyzeQuery: builder.mutation<
            {keywords: string[]; intent: string},
            string
        >({
            query: (message) => ({
                url: "chat/completions",
                method: "POST",
                body: {
                    model: "gpt-4o-mini",
                    messages: [
                        {
                            role: "system",
                            content: systemPrompt,
                        },
                        {
                            role: "user",
                            content: message,
                        },
                    ],
                },
            }),
            transformResponse: (response: any) => {
                return JSON.parse(response?.choices[0]?.message.content);
                /* return response.choices[0].message.content; */
            },
        }),
        likeMessage: builder.mutation<void, {messageId: string}>({
            query: ({messageId}) => ({
                url: `like`, // TODO - Update when endpoint has been implemented
                method: "PATCH",
                body: messageId,
            }),
        }),
        dislikeMessage: builder.mutation<void, {messageId: string}>({
            query: ({messageId}) => ({
                url: `dislike`, // TODO - Update when endpoint has been implemented
                method: "PATCH",
                body: messageId,
            }),
        }),
    }),
});

export const {
    useGetChatCompletionQuery,
    useLazyGetChatCompletionQuery,
    useAnalyzeQueryMutation,
} = chatApi;
