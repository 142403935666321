import {
    createTheme,
    rem,
    defaultVariantColorsResolver,
    Button,
    Tooltip,
    Modal,
    darken,
} from "@mantine/core";
import "@mantine/core/styles.css";
import classes from "./variants.module.css";

export default createTheme({
    /* fontFamily: "Lato, sans-serif", */
    fontSizes: {
        xxl: rem(24),
        xxxl: rem(30),
    },
    components: {
        Button: Button.extend({
            classNames: classes,
            defaultProps: {size: "xs", radius: "md"},
        }),
        Tooltip: Tooltip.extend({
            defaultProps: {
                openDelay: 300,
                position: "bottom",
            },
        }),
        Modal: Modal.extend({
            defaultProps: {
                radius: "lg",
                transitionProps: {transition: "fade", duration: 150},
            },
            styles: {
                title: {
                    fontWeight: 600,
                },
            },
        }),
    },
    variantColorResolver: (input) => {
        const defaultResolvedColors = defaultVariantColorsResolver(input);

        // Make variant "light" with color "gray" a bit darker for visiblity and make hover darker so hover is visible.
        if (input.variant === "light" && input.color === "gray") {
            const defaultColors = defaultVariantColorsResolver({
                ...input,
                variant: "light",
            });

            return {
                ...defaultColors,
                background: darken(defaultColors.background, 0.02),
                color: darken(defaultColors.color, 0.5),
                hover: darken(defaultColors.hover, 0.05),
            };
        }

        // Make variant "message-button" use "subtle" variant default colors.
        // New style overrides are found in variants.module.css.
        if (input.variant === "message-button") {
            return defaultVariantColorsResolver({
                ...input,
                variant: "subtle",
            });
        }

        if (input.variant === "rounded") {
            return defaultVariantColorsResolver({
                ...input,
                variant: "light",
            });
        }

        return defaultResolvedColors;
    },
});
